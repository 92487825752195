import React from 'react';
import { ExclamationCircleIcon } from '@heroicons/react/20/solid';

import { useInstance } from 'context/instance';
import swedenIcon from 'assets/images/sweden.png';
import norwayIcon from 'assets/images/norway.png';
import ubuntuImage from 'assets/images/ubuntu.png';
import debianImage from 'assets/images/debian.png';

import Keys from './Keys';
import Ipvs from './Ipv4s';

import classes from './summary.module.scss';

const Items = (() => {
  const { titleClass, gpuCountClass } = classes;

  const context = useInstance();
  const state = context[0];
  const { instance, validation } = state;
  const { name, network, image, flavor, location, error } = instance;

  const renderWrapperFlavorValue = () => {
    return (
      <span className='row items-center'>
          NVIDIA {!flavor?.customName ? '' : flavor?.customName}
        <span className={gpuCountClass}>
          {flavor.gpu_count} GPU{flavor.gpu_count > 1 && <>'s</>}
        </span>
      </span>
    );
  };

  const data = [
    {
      title: 'Name',
      value: name,
      validationText: error ?  error : 'Add Name',
      isValid: validation.name,
      testDataId: 'vm_name',
    },
    {
      title: 'Location',
      value: location,
      validationText: '',
      isValid: false,
      testDataId: 'location',
    },
    {
      title: 'Flavor',
      value: renderWrapperFlavorValue(),
      validationText: '',
      isValid: false,
      testDataId: 'flavor',
    },
    {
      title: 'Image',
      value: !image?.name ? '' : image?.name,
      validationText: '',
      isValid: false,
      testDataId: 'image',
    },
    {
      title: 'Network',
      value: network?.network_name,
      validationText: 'Add Network',
      isValid: validation.network,
      testDataId: 'network',
    },
  ];

  const showInstanceTypeInData = data;

  return (
    <dl className='divide-y divide-gray-200 border-b border-gray-200 m-0'>
      {showInstanceTypeInData.map((item) => (
        <div key={item.title} className='col pl-6 py-[10px] font-medium'>
          <dt className={titleClass}>{item.title}</dt>
          <dd className='row justify-start items-center text-sm' data-testid={item.testDataId}>
            {item.title === 'Location' &&
              <img
                src={location === 'Sweden' ? swedenIcon : norwayIcon}
                alt='norway'
                className='w-4 mr-2'
              />
            }
            {item.title === 'Image' &&
              <img
                src={image?.name?.includes('Ubuntu') ? ubuntuImage : debianImage}
                alt='norway'
                className='w-4 mr-2'
              />
            }
            {(((item.title === 'Network' && !network.uuid) || (item.title === 'Name' && !name && !name.trim()))  && !item.isValid) ?
              <p className='text-customTextBlack2withOpacity1'>Add {item.title}</p> :
              item.value ? <p className={`truncate ${!name || name === ' ' ? '' : 'w-72'}`}>{item.value}</p> : null
            }
            {item.isValid &&
              <div className='row'>
                <ExclamationCircleIcon className='fill-customDarkRed w-[18px] mr-[10px]' />
                <p className='text-customDarkRed '>{item.validationText}</p>
              </div>
            }
          </dd>
        </div>
      ))}
      <Keys />
      <Ipvs />
    </dl>
  );
});

export default Items;
