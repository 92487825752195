import React from 'react';

import { useDashboard } from 'context/dashboard';
import IconSpinner from 'assets/icons/IconSpinner';

import ScheduledMaintenanceComponent from './ScheduledMaintenanceComponent';
import classes from './index.module.scss';

import useGetMaintenance from '../useGetMaintenance';

export default function ScheduledMaintenance() {
  // hooks
  const { loading } = useGetMaintenance();

  const contextDashboard = useDashboard();
  const stateDashboard = contextDashboard[0];
  const { maintenance } = stateDashboard;

  const scheduledMaintenance = maintenance && maintenance.scheduled;
  const scheduledMaintenanceFullTime = scheduledMaintenance &&
    scheduledMaintenance[0] &&
    scheduledMaintenance[0].full_date_for_ui;

  const positionOnScheduleContentInsideElement = scheduledMaintenance?.length > 1 ?
    'items-start' : 'items-center';

  const {
    statusScheduledClass,
  } = classes;

  return (
    <>
      {loading ?
        <div className={`flex row justify-center items-center ${statusScheduledClass}`}>
          <IconSpinner customStyle='w-4 h-4' />
        </div>
        :
        <div className={`${positionOnScheduleContentInsideElement} ${statusScheduledClass}`}>
          {(Array.isArray(scheduledMaintenance) && scheduledMaintenance?.length !== 0) &&
            <ScheduledMaintenanceComponent
              scheduledMaintenance={scheduledMaintenance}
              scheduledMaintenanceFullTime={scheduledMaintenanceFullTime}
            />
          }
        </div>
      }
    </>
  );
};
