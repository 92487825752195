import { Link, Redirect, useLocation } from 'react-router-dom';

import Typography from 'components/typography';
import StatusVmViewComponent from 'components/vms/StatusVmViewComponent';
import { useGetRoutesPagePath } from 'hooks/useGetRoutesPagePath';

import useGetItemsDetailPage from '../../../hooks/useGetItemsDetailPage';

type TProps = {
  item: {
    title: string,
    value: string,
  }
};

export default function Item(props: TProps) {
  // props
  const { item } = props;

  // variables
  const { handlePagePaths } = useGetRoutesPagePath();
  const { networkUuid } = useGetItemsDetailPage();
  const location = useLocation();
  const isNetworkPage = location.pathname.includes('/networks');

  const networkLink = (value: string) => {
    return (
      <Link to={handlePagePaths(networkUuid).network}>
        <Typography
          text={value}
          fontWeight='thin'
          color='customColorDarkBlue80'
          fontSize='s'
          customStyle='cursor-pointer hover:text-primaryColorEasternBlueBtnHover'
        />
      </Link>
    );
  };

  const showRightTypeOfElement = (element: 'Network' | string, value: string) => {
    if (element === 'Network UUID') return networkLink(value);
    return (
      <Typography
        text={value}
        fontWeight='thin'
        color='customColorDarkBlue80'
        fontSize='s'
      />
    );
  };

  if (item.title === 'Type' && item.value === '-') {
    return <Redirect to={handlePagePaths().instances} />;
  };

  return (
    <div
      key={item.title}
      className='flex col'
    >
      <div className='mb-2'>
        <Typography
          text={item.title}
          fontWeight='medium'
          color='customColorDarkBlue80'
          fontSize='s'
        />
      </div>
      {item.title === 'Status' && isNetworkPage ?
        <StatusVmViewComponent
          status={item.value}
          fontSize='s'
          fontWeight='medium'
        />
        :
        showRightTypeOfElement(item.title, item.value)
      }
    </div>
  );
};
