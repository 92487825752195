import React, { lazy, Suspense } from 'react';
import { PencilIcon } from '@heroicons/react/24/solid';

import { useDashboard } from 'context/dashboard';
import ActionItem from 'feature/actions/ActionItem';
import useOpenRenameModal from 'hooks/useOpenRenameModal';
import Button from 'components/buttons';
import UIElementWithTooltip from 'feature/ui-element-with-tooltip';
import Typography from 'components/typography';
import { TDataTestIdsOverviewPageContentMainHeaderNetworkPage } from 'data-test-ids/types/overview-page';
import { SpinnerLazyLoading } from 'components/spinners/SpinnerLazyLoading';
import { useGetRoutesPagePath } from 'hooks/useGetRoutesPagePath';

const RenameNetwork = lazy(() => import('components/forms/rename-network/RenameNetwork'));

type TProps = {
  dataTestIds: TDataTestIdsOverviewPageContentMainHeaderNetworkPage,
};

/**
 * @description The Header component is used on the network page and
 * contains the name and actions rename and delete.
 * @augments Network_page
 * @param props dataTestIds
 * @param dataTestIds required
 * @returns React node - LightGrayWrapper component
*/

export default function Header(props: TProps) {
  // props
  const { dataTestIds } = props;

  // variables
  const contextDashboard = useDashboard();
  const state = contextDashboard[0];
  const { fetchFunc } = state;
  const { data } = fetchFunc.network.get;
  const {
    handleOpenRenameModal,
    handleCloseRenameModal,
    state: rename,
  } = useOpenRenameModal();
  const { handlePagePaths } = useGetRoutesPagePath();

  return (
    <>
      <Typography
        text={data.network_name}
        truncate='truncate'
        width='660px'
      />
      <div
        data-testid='test_id_network_page_header_wrapper'
        className='row'
      >
        <UIElementWithTooltip
          text='rename'
          dataTestId={dataTestIds.tooltips.tooltipRename}
          element={
            <Button
              uiStyle='white-with-icon-tooltip'
              handleClick={() => handleOpenRenameModal(data)}
              customStyle='all-items-center p-2'
              dataTestId={dataTestIds.buttonRename}
            >
              <PencilIcon
                className='w-5 stroke-2 fill-customColorDarkBlue'
              />
            </Button>
          }
        />
        <ActionItem
          uuid={data.uuid}
          route={handlePagePaths(data.uuid).network}
          url='network/network'
          page='network'
          fetchMethod='delete'
          actionName='DELETED'
          itemName={data.network_name}
          dataTestIds={dataTestIds.buttonDelete}
          typeAction='button-action'
        />
      </div>
      {rename.openEditModal &&
        <Suspense fallback={<SpinnerLazyLoading />}>
          <RenameNetwork
            data={rename.network}
            open={rename.openEditModal}
            onCancel={handleCloseRenameModal}
            dataTestIds={dataTestIds.modalFormRenameItem}
          />
        </Suspense>
      }
    </>
  );
};
