import React, { SetStateAction } from 'react';

import { useDashboard } from 'context/dashboard';
import clusters from 'data/avaliableClusters.json';
import CustomSelect from 'components/input/Select';
import { TDataTestIdsPortalModalFormContent } from 'data-test-ids/types/general-pages/portalModal';
import { TNewIPv4 } from 'types/index';
import { useTenants } from 'context/tenants';

import Spinner from '../../spinners/Spinner';
import Info from '../../Info';

type TProps = {
  handleSelectedClusterName: (value: SetStateAction<string>) => void,
  handleClusterNameError: (value: string) => void,
  handleUpdateFormData: (value: SetStateAction<TNewIPv4>) => void,
  formData: {},
  selectedClusterName: string,
  clusterError: string,
  dataTestIds: TDataTestIdsPortalModalFormContent,
};

export default function Content(props: TProps) {
  // props
  const {
    handleSelectedClusterName,
    handleClusterNameError,
    handleUpdateFormData,
    formData,
    selectedClusterName,
    clusterError,
    dataTestIds,
  } = props;

  // variables
  const contextDashboard = useDashboard();
  const stateDashboard = contextDashboard[0];
  const contextTenants = useTenants();
  const stateTenants = contextTenants[0];
  const { selectedTenantId } = stateTenants;
  const { fetchFunc } = stateDashboard;
  const { error, loading } = fetchFunc.ip.post;

  const handleChange = (value: string, uuid: string) => {
    handleSelectedClusterName(value);
    handleClusterNameError('');
    handleUpdateFormData({
      ...formData,
      tenant_uuid: selectedTenantId,
      cluster_uuid: uuid,
    });
  };

  if (error) {
    return  <Info status='error' msg={error} />;
  };

  if (loading) {
    return  <Spinner spaceAroundSpinner />;
  };

  return (
    <div
      data-testid={dataTestIds.wrapper}
      className='p-6'
    >
      <CustomSelect
        handleChange={handleChange}
        name='cluster'
        label='Cluster'
        value={selectedClusterName}
        isShowLabel={false}
        selectFeedback={clusterError}
        options={clusters}
        optionValue='name'
        id='cluster_uuid'
        dataTestId={dataTestIds.inputSelect}
      />
    </div>
  );
};
