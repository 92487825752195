import React, { forwardRef } from 'react';

import Info from 'components/Info';
import Spinner from 'components/spinners/Spinner';
import { ITabsItemFlavor, TFlavorTabs } from 'types';
import { useGetFlavors } from 'hooks/useGetFlavors';
import { useInstance } from 'context/instance';

import Tabs from './Tabs';

import TitleSection from '../TitleSection';

/*
 *
 * @returns div
 */

const Flavor = forwardRef<HTMLDivElement>((props, ref) => {
  // variables
  const { error, loading } = useGetFlavors();
  const context = useInstance();
  const state = context[0];
  const { filterFlavors: { A6000 } } = state;


  const tabsObject: {
    name: TFlavorTabs,
    items: Array<ITabsItemFlavor>,
  }[] = [
    {
      name: 'NVIDIA RTX A6000',
      items: A6000,
    },
  ];

  // func
  const returnContent = () => {
    if (error) return <Info status='error' msg={error} />;
    if (loading) return <Spinner />;
    return (
      <Tabs
        tabs={tabsObject}
      />
    );
  };

  return (
    <div
      className='col-span-6'
    >
      <TitleSection title='Flavor' />
      <div className='mt-6'>
        {returnContent()}
      </div>
    </div>
  );
});

export default Flavor;
