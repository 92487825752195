import { useOktaAuth } from '@okta/okta-react';

/**
 * @description useLogout - hooks, clean storage data and logout from interface
 * @returns handleLogout func
 */

export default function useLogout() {
  // valiables
  const { oktaAuth } = useOktaAuth();

  // func
  const handleLogout = async() => {
    oktaAuth.signOut();
    localStorage.removeItem('dashboard_token');
    localStorage.removeItem('selectedTenantStorageUuid');
    localStorage.removeItem('selectedTenantStorage');
    localStorage.removeItem('selectedTenantIdStorage');
    localStorage.removeItem('tenantsStorage');
  };

  return { handleLogout };
};
