import React, { ReactNode } from 'react';

import classes from './index.module.scss';

type uiStyleButtons =
  'white-with-icon-tooltip' |
  'primary' |
  'secondary' |
  'red' |
  'green-icon' |
  'white' |
  'detach' |
  'log out' |
  'small-secondary' |
  'white-with-icon-tooltip' |
  'menu-with-title-action' |
  'gray' |
  'only-text' |
  'gray-small' |
  'without-style' |
  'tab'

type TProps = {
  uiStyle: uiStyleButtons,
  handleClick?: (event?: any) => void,
  title?: string,
  type?: 'button' | 'submit' | 'reset' | undefined,
  disabled?: boolean,
  customStyle?: string,
  children?: ReactNode,
  dataTestId?: string,
  handleMouseEnter?: () => void,
  handleMouseLeave?: () => void,
};

/**
 * @description Button - a custom button component
 * @param props uiStyle, handleClick, title, type, disabled, customStyle, children, dataTestId, handleMouseEnter, handleMouseLeave
 * @returns <button />
 */

export default function Button(props: TProps) {

  const {
    uiStyle,
    handleClick,
    title,
    type,
    disabled=false,
    customStyle='',
    children='',
    dataTestId,
    handleMouseEnter,
    handleMouseLeave,
  } = props;

  const {
    primaryButtonClass,
    secondaryButtonClass,
    disabledGreenButtonClass,
    disabledRedButtonClass,
    whiteButtonClass,
    redButtonClass,
    greenButtonWithIconClass,
    detachButtonClass,
    logoutButtonClass,
    secondaryButtonSmallClass,
    whiteButtonWithIconAndTooltipClass,
    menuActionItemButtonClass,
    grayButtonClass,
    disabledGrayButtonClass,
    onlyTextButtonClass,
    greySmallButtonClass,
    disabledGreySmallButtonClass,
    grayTabButtonClass,
  } = classes;

  const getUiStyle = () => {
    if (uiStyle === 'primary') return primaryButtonClass;
    if (uiStyle === 'secondary') return secondaryButtonClass;
    if (uiStyle === 'red') return redButtonClass;
    if (uiStyle === 'green-icon') return greenButtonWithIconClass;
    if (uiStyle === 'white') return whiteButtonClass;
    if (uiStyle === 'detach') return detachButtonClass;
    if (uiStyle === 'log out') return logoutButtonClass;
    if (uiStyle === 'small-secondary') return secondaryButtonSmallClass;
    if (uiStyle === 'white-with-icon-tooltip') return whiteButtonWithIconAndTooltipClass;
    if (uiStyle === 'menu-with-title-action') return menuActionItemButtonClass;
    if (uiStyle === 'gray') return grayButtonClass;
    if (uiStyle === 'only-text') return onlyTextButtonClass;
    if (uiStyle === 'gray-small') return greySmallButtonClass;
    if (uiStyle === 'tab') return grayTabButtonClass;
    if (uiStyle === 'without-style') return '';
  };

  const setStyle = () => {
    if (disabled && uiStyle === 'primary') {
      return disabledGreenButtonClass;
    }
    if (disabled && uiStyle === 'red') {
      return disabledRedButtonClass;
    }
    if (disabled && uiStyle === 'gray') {
      return disabledGrayButtonClass;
    }
    if (disabled && uiStyle === 'gray-small') {
      return disabledGreySmallButtonClass;
    }
    return getUiStyle();
  };

  return (
    <button
      data-testid={dataTestId}
      type={type}
      onClick={handleClick}
      className={`${customStyle} ${setStyle()} capitalize`}
      disabled={disabled}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {title && title}
      {children}
    </button>
  );
};
