import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { SecureRoute, LoginCallback } from '@okta/okta-react';

import 'styles/index.scss';

import Instances from 'pages/instances';
import Networks from 'pages/Networks';
import Network from 'pages/Network';
import SSHKeys from 'pages/SSHKeys';
import IPv4s from 'pages/IPv4s';
import SignIn from 'pages/login/SignIn';
import CreateInstance from 'pages/create-instance/CreateInstance';
import NotFound from 'pages/error-statuses/NotFound';
import Overview from 'pages/overview';
import MaintenanceApi from 'pages/MaintenanceApi';
import Tenants from 'pages/tenants';
import Template from 'pages/Template';
import Instance from 'pages/Instance';
import IPv4 from 'pages/IPv4';

export const homePage = '/';

/**
 * @description Routes
 * @returns all routes
 */

export default function Routes() {
  return (
    <Switch>
      <SecureRoute
        path='/:tenantId/instances/new'
        children={
          <Template>
            <CreateInstance />
          </Template>
        }
      />
      <SecureRoute
        path='/:tenantId/instances/:instanceId'
        children={
          <Template>
            <Instance />
          </Template>
        }
      />
      <SecureRoute
        exact
        path='/:tenantId/instances'
        children={
          <Template>
            <Instances />
          </Template>
        }
      />
      <SecureRoute
        path='/:tenantId/networks/:networkId'
        children={
          <Template>
            <Network />
          </Template>
        }
      />
      <SecureRoute
        path='/:tenantId/networks'
        children={
          <Template>
            <Networks />
          </Template>
        }
      />
      <SecureRoute
        path='/:tenantId/sshkeys'
        children={
          <Template>
            <SSHKeys />
          </Template>
        }
      />
      <SecureRoute
        path='/:tenantId/ipv4s/:ipv4Id'
        children={
          <Template>
            <IPv4 />
          </Template>
        }
      />
      <SecureRoute
        path='/:tenantId/ipv4s'
        children={
          <Template>
            <IPv4s />
          </Template>
        }
      />
      <SecureRoute
        path='/tenants'
        children={<Tenants />}
      />
      <SecureRoute
        exact
        path='/'
        children={
          <Template>
            <Overview/>
          </Template>
        }
      />

      <Route exact path='/login' component={SignIn} />
      <Route path='/login/callback' component={LoginCallback} />
      <Route path='/maintenance' component={MaintenanceApi} />

      <Route path='*' component={NotFound} />
    </Switch>
  );
};
