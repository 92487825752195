import React, { forwardRef, useEffect, useState } from 'react';

import Spinner from 'components/spinners/Spinner';
import Info from 'components/Info';
import { ITabsItemImage } from 'types';
import { api } from 'helpers/api';

import Items from './Items';
import { useFilterImages } from './useFilterImages';

import TitleSection from '../TitleSection';

const Images = forwardRef<HTMLDivElement>((props, ref) => {
  const [error, setError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [allImages, setAllImages] = useState<ITabsItemImage[]>([]);

  const url = 'info/images';

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    async function loadData() {
      const request = api.useFetchData<[], Array<ITabsItemImage>>(url, 'get', signal);
      try {
        setLoading(true);
        const getAllImages = await request;
        setAllImages(getAllImages);
        setError('');
      }
      catch (error) {
        if (error instanceof Error) {
          setError(error?.message || '');
        }
        setAllImages([]);
      }
      finally {
        setLoading(false);
      }
    }
    loadData();

    return () => {
      controller.abort();
      setAllImages([]);
    };
  }, [url]);

  const images = useFilterImages(allImages);

  const returnContent = () => {

    if (error) return <Info status='error' msg={error} />;
    if (loading) return <Spinner />;
    return (
      <Items images={images}/>
    );
  };

  return (
    <div
      ref={ref}
      className='col-span-6'
    >
      <TitleSection
        title='Image'
        description='The image running on your instance. This can be an operating system, an InstantApp, or one of your custom images.'
      />
      {returnContent()}
    </div>
  );
});

export default Images;
