import { ITabsItemFlavor } from 'types';

export const getFilterFlavors = (flavors: ITabsItemFlavor[]) => {
  // variables

  const flavorWithoutNvswitch = flavors
    .flat()
    .filter((item: ITabsItemFlavor ) => item.nvswitch_count === 0);

  const A6000 = flavorWithoutNvswitch.flat()
    .filter((item: ITabsItemFlavor ) => item.name.includes('RTXA6000'))
    .map((item: ITabsItemFlavor ) => ({
      ...item,
      cluster: {
        uuid: '4ec2bb6d-cb56-4821-91eb-779eb5a45fd5',
        name: 'ndcloud-underground',
      },
      customName: 'RTX A6000',
      selected: false,
      type: 'Virtual machine',
    }));

  const H100 = flavorWithoutNvswitch.flat()
    .filter((item: ITabsItemFlavor ) => item.name.includes('H100'))
    .map((item: ITabsItemFlavor ) => ({
      ...item,
      cluster: {
        uuid: '4ec2bb6d-cb56-4821-91eb-779eb5a45fd5',
        name: 'ndcloud-underground',
      },
      customName: 'HGX H100',
      selected: true,
      type: 'Bare metal',
    }));

  const A100 = flavorWithoutNvswitch
    .flat()
    .filter((item: ITabsItemFlavor ) => item.gpu_type === 'A100 80GB')
    .map((item: ITabsItemFlavor ) => ({
      ...item,
      cluster: {
        uuid: 'bb7b0a68-265f-4734-a4ea-89911b2ddff7',
        name: 'ndcloud-arcticcircle',
      },
      customName: 'A100',
      selected: false,
      type: 'Bare metal',
    }));

  const NVSWITCH_A100 = flavors
    .flat()
    .filter((item: ITabsItemFlavor) => item.nvswitch_count > 0)
    .map((item: ITabsItemFlavor ) => ({
      ...item,
      cluster: {
        uuid: 'bb7b0a68-265f-4734-a4ea-89911b2ddff7',
        name: 'ndcloud-arcticcircle',
      },
      customName: 'A100',
      selected: false,
      type: '-',
    }));

  return { A6000, H100, NVSWITCH_A100, A100};
};
