import React, { useRef } from 'react';
import useOnClickOutside from 'hooks/useClickOutside';

import PopUpUserData from './pop-up-user-data';
import UserIconComponent from './pop-up-user-data/components/UserIconComponent';

import classes from '../index.module.scss';

/**
 * @description UserData is a component shows a clickable icon that opens a pop-up
 * that contains information about the user and buttons to log out and set up your account.
 *
 * @returns details
 */

export default function UserData() {
  // variables
  const ref = useRef(null);

  // style
  const { userDataWrapperClass } = classes;

  // func
  const handleClickOutside = () => {
    const el = document.getElementById('user-info-pop-up') as HTMLDetailsElement;
    el.open = false;
  };

  useOnClickOutside(ref, handleClickOutside);

  return (
    <details
      ref={ref}
      id='user-info-pop-up'
      data-testid='test-id-main-header-user-data'
    >
      <summary className='ml-4'>
        <UserIconComponent
          dataTestId='test-id-main-header-user-data-icon'
          isCursor={true}
        />
      </summary>
      <div
        className={userDataWrapperClass}
        onMouseLeave={handleClickOutside}
        data-testid='test-id-main-header-pop-up'
      >
        <PopUpUserData />
      </div>
    </details>
  );
};
