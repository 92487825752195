export default function getNameOfFlavor(uuid: string) {
  // ndcloud-underground Norway, Europe
  if (uuid === '94d76997-44ec-4f1e-8291-231de42b6030') {
    return 'RTXA6000.1GPU';
  }
  if (uuid === 'b5917017-7ca4-403e-b08b-ba5cc31c7d2a') {
    return 'RTXA6000.2GPU';
  }
  if (uuid === '1e6e6513-9ee1-4f6d-a717-55b99b838941') {
    return 'RTXA6000.4GPU';
  }

  // ndcloud-arcticcircle Sweden, Europe
  if (uuid === 'bd8979d3-8381-45bb-a489-a59bc003192a') {
    return 'A100.1GPU';
  }
  if (uuid === '725f73c9-a6c2-4bde-86d7-face130f38b7') {
    return 'A100.2GPU';
  }
  if (uuid === '583e2294-c062-4258-a670-19f4b17ccd80') {
    return 'A100.4GPU';
  }
  if (uuid === '9b7fdf08-cc98-4b7c-8bf7-9ff7086815ae') {
    return 'A100.8GPU';
  }
  if (uuid === '6b2f0f5a-5bd2-4c19-81b6-8e59abf8f43c') {
    return 'A100.8GPU.6NV';
  }

  // demo flavor "Nvidia HGX H100.8GPU"
  if (uuid === '94d76100-44ec-4f1e-8100-100de42b6100') {
    return 'HGX H100.8GPU';
  }

  return uuid;
};
