import React from 'react';

import { useDashboard } from 'context/dashboard';
import { TFetchFuncPage, TFetchFuncRequestMethod } from 'types';
import getVMsStatus, { TActionsMenuComponent } from 'helpers/getStyleVMStatus';
import Info from 'components/Info';
import Spinner from 'components/spinners/Spinner';

type TProps = {
  actionName: TActionsMenuComponent,
  itemName: string,
  page: TFetchFuncPage,
  fetchMethod: TFetchFuncRequestMethod,
};

export default function ContentView(props: TProps) {
  const {
    actionName,
    itemName,
    fetchMethod,
    page,
  } = props;

  const context = useDashboard();
  const state = context[0];
  const { fetchFunc } = state;
  const { error, loading, message } = fetchFunc[page][fetchMethod];

  if (error) {
    return  <Info status='error' msg={error} />;
  };

  if (loading) {
    return  <Spinner spaceAroundSpinner />;
  };

  if (message) {
    return  <Info status='success' msg={message} />;
  };

  return (
    <div className='pt-6 pl-6 pb-[61px]'>
      <p className='text-sm font-medium pb-[10px]'>
        Do you really want to {getVMsStatus(actionName).lowercase}?
      </p>
      <div >
        <ul className='list-disc pl-6 text-xs font-medium'>
          <li className='list-inside truncate w-80'>
            {itemName}
          </li>
        </ul>
      </div>
    </div>
  );
};
