import React, { useState } from 'react';
import { ArrowUpRightIcon } from '@heroicons/react/24/outline';
import { useOktaAuth } from '@okta/okta-react';

import MenuComponent from 'components/menu';
import { useDashboard } from 'context/dashboard';
import Button from 'components/buttons';
import FormModalTemplate from 'layouts/FormModalTemplate';
import PortalModal from 'portals/Modal';
import ActionItem from 'feature/actions/ActionItem';
import StatusVmViewComponent from 'components/vms/StatusVmViewComponent';
import Typography from 'components/typography';
import { TDataTestIdsOverviewPageContentMainHeaderVmPage } from 'data-test-ids/types/overview-page';

import SpiceConsole from './SpiceConsole';

import { getCorrectListOfActions } from '../../helpers/getCorrectListOfActions';

import { disconnect, connect } from '../../spiceHTML5';

type TProps = {
  dataTestIds: TDataTestIdsOverviewPageContentMainHeaderVmPage,
};

/**
 *
 * @param props dataTestIds
 * @returns
 */

export default function Subheader(props: TProps) {
  // state
  const [openTerminal, setOpenTerminal] = useState<boolean>(false);

  // props
  const {dataTestIds} = props;

  // variables
  const contextDashboard = useDashboard();
  const state = contextDashboard[0];
  const { authState } = useOktaAuth();
  const token = authState?.accessToken?.accessToken;
  const { fetchFunc } = state;
  const { name, status, uuid } = fetchFunc.instance.get.data;
  const vmStatus = status === 'DELETED' || status === 'DELETION_REQUESTED';
  const resetStatus = status === 'RESET_REQUESTED' || status === 'REBOOT_REQUESTED' || status === 'REQUESTED';
  const url = encodeURI(`${process.env.REACT_APP_API_URL_SPICE}/vm/vm/${uuid}/console?token=${token}`);
  const instanceType = localStorage.getItem('selectedInstanceType');

  // func
  const handleCloseTerminal = () => {
    disconnect();
    setOpenTerminal(false);
  };

  const handleOpenTerminal = () => {
    connect(url, '');
    setOpenTerminal(true);
  };

  const getType = fetchFunc.instance.get.data?.mtype ? fetchFunc.instance.get.data?.mtype : instanceType;

  return (
    <>
      <div>
        <Typography
          text={name}
          truncate='truncate'
          width='660px'
          dataTestId={dataTestIds.name}
        />
        <StatusVmViewComponent
          status={status}
          fontSize='xxs'
          fontWeight='medium'
        />
      </div>
      {(getType === 'baremetal') ?
        null :
        <>
          <div className='row items-center'>
            {status === 'VM_ACTIVE' &&
              <Button
                type='button'
                customStyle='mr-2'
                handleClick={handleOpenTerminal}
                title='Open Console'
                uiStyle='primary'
                dataTestId={dataTestIds.btnOpenTerminal}
              >
                <ArrowUpRightIcon
                  className='iconClass'
                  aria-hidden='true'
                />
              </Button>
            }
            {(!vmStatus && !resetStatus) &&
              <>
                <ActionItem
                  uuid={uuid}
                  url={url}
                  route='vm/vm'
                  page='instance'
                  fetchMethod='post'
                  actionName='RESET_REQUESTED'
                  itemName={name}
                  typeAction='button-action'
                  dataTestIds={dataTestIds.tooltips}
                />
                <ActionItem
                  uuid={uuid}
                  url={url}
                  route='vm/vm'
                  page='instance'
                  fetchMethod='post'
                  actionName='REBOOT_REQUESTED'
                  itemName={name}
                  typeAction='button-action'
                  dataTestIds={dataTestIds.tooltips}
                />
              </>
            }
            <MenuComponent
              uuid={uuid}
              lastElement={false}
              hideLeftMargin
              actions={getCorrectListOfActions(status, 'vm')}
              url='vm/vm'
              route=''
              page='instance'
              fetchMethod='post'
              itemName={name}
            />
          </div>
          {openTerminal &&
            <PortalModal
              open={openTerminal}
              onCancel={handleCloseTerminal}
              width='w-auto'
            >
              <FormModalTemplate
                handleSubmitButtonName=''
                onCancel={handleCloseTerminal}
                handleSubmit={() => {}}
                hideBtns={true}
                header='Console'
              >
                <SpiceConsole url={url} />
              </FormModalTemplate>
            </PortalModal>
          }
        </>
      }
    </>
  );
};
