import { useEffect } from 'react';
// import useFilterInstances from 'hooks/useFilterInstances';
// import { useFilterNetworks } from 'hooks/useFilterNetworks';
// import { useFilterIps } from 'hooks/useFilterIps';

import Item from './Item';
import classes from './overview.module.scss';

import { useItemsOverview } from '../../hooks/useItemsOverview';

export default function OverviewComponent() {
  // variables
  const { titleClass } = classes;
  const { ITEMS } = useItemsOverview();
  // const { virtualmachine, baremetal, instances } = useFilterInstances();
  // const networks  = useFilterNetworks();
  // const ips  = useFilterIps();

  useEffect(() => {
    document.title = 'Taiga Cloud | Overview';
  }, []);

  return (
    <>
      <p className={titleClass}>Overview</p>
      <div className='row'>
        {ITEMS.map((item) => <Item key={item.id} item={item} />)}
      </div>
      <hr className='mt-4' />
      {/* <hr className='h-1' /> */}
      {/* <hr />
      Instances
      <hr />
        count: {instances.amount} <br />
        norway: {instances.byClusters.norway} <br />
        sweden: {instances.byClusters.sweden}

      <div>
        <ul>
          <li>scheduled: {instances.byStatus.scheduled} </li>
          <li>networkReady: {instances.byStatus.networkReady} </li>
          <li>requested: {instances.byStatus.requested} </li>
          <li>reset: {instances.byStatus.reset} </li>
          <li>powerOff: {instances.byStatus.powerOff} </li>
          <li>reboot: {instances.byStatus.reboot} </li>
          <li>error: {instances.byStatus.error} </li>
          <li>errorCreation: {instances.byStatus.errorCreation} </li>
          <li>deletionRequest: {instances.byStatus.deletionRequest} </li>
          <li>vmActive: {instances.byStatus.vmActive} </li>
        </ul>
      </div>

      Vms
      <hr />
        count: {virtualmachine.amount} <br />
        norway: {virtualmachine.byClusters.norway} <br />
        sweden: {virtualmachine.byClusters.sweden}

      <div>
        <ul>
          <li>scheduled: {virtualmachine.byStatus.scheduled} </li>
          <li>networkReady: {virtualmachine.byStatus.networkReady} </li>
          <li>requested: {virtualmachine.byStatus.requested} </li>
          <li>reset: {virtualmachine.byStatus.reset} </li>
          <li>powerOff: {virtualmachine.byStatus.powerOff} </li>
          <li>reboot: {virtualmachine.byStatus.reboot} </li>
          <li>error: {virtualmachine.byStatus.error} </li>
          <li>errorCreation: {virtualmachine.byStatus.errorCreation} </li>
          <li>deletionRequest: {virtualmachine.byStatus.deletionRequest} </li>
          <li>vmActive: {virtualmachine.byStatus.vmActive} </li>
        </ul>
      </div>
      Bms
      <hr />
        count: {baremetal.amount} <br />
        norway: {baremetal.byClusters.norway} <br />
        sweden: {baremetal.byClusters.sweden}

      <div>
        <ul>
          <li>scheduled: {baremetal.byStatus.scheduled} </li>
          <li>networkReady: {baremetal.byStatus.networkReady} </li>
          <li>requested: {baremetal.byStatus.requested} </li>
          <li>reset: {baremetal.byStatus.reset} </li>
          <li>powerOff: {baremetal.byStatus.powerOff} </li>
          <li>reboot: {baremetal.byStatus.reboot} </li>
          <li>error: {baremetal.byStatus.error} </li>
          <li>errorCreation: {baremetal.byStatus.errorCreation} </li>
          <li>deletionRequest: {baremetal.byStatus.deletionRequest} </li>
          <li>vmActive: {baremetal.byStatus.vmActive} </li>
        </ul>
      </div>

      Networks
      <hr />
        count: {networks.amount.count} <br />
        norway: {networks.amount.norway} <br />
        sweden: {networks.amount.sweden} <br />

        Ips
      <hr />

        all: {ips.all.ips.length} attached: {ips.all.attached.length} not attached: {ips.all.notAttached.length} <br />
        norway: {ips.byClusters.norway.amount}  attached: {ips.byClusters.norway.attached.length} not attached: {ips.byClusters.sweden.attached.length}<br />
        sweden: {ips.byClusters.sweden.amount}  attached: {ips.byClusters.sweden.attached.length} not attached: {ips.byClusters.sweden.notAttached.length} */}

    </>
  );
};
