import React, { ChangeEvent, useState } from 'react';

import { useDashboard } from 'context/dashboard';
import { setNewNetwork } from 'actions/dashboard';
import clusters from 'data/avaliableClusters.json';
import CustomInput from 'components/input/Input';
import CustomSelect from 'components/input/Select';
import { TDataTestIdsPortalModalFormContent } from 'data-test-ids/types/general-pages/portalModal';
import { useTenants } from 'context/tenants';

import AlertSection from './AlertSection';

type TEvent = ChangeEvent<HTMLSelectElement> | ChangeEvent<HTMLInputElement>;

type TProps = {
  page: 'Networks' | 'New virtual machine',
  nameError: string,
  clusterError: string,
  handleNameError: (value: string) => void,
  handleClusterError: (value: string) => void,
  dataTestIds: TDataTestIdsPortalModalFormContent,
};

export default function Inputs(props: TProps) {
  const [selectedClusterName, setSelectedClusterName] = useState<string>('');

  const contextDashboard = useDashboard();
  const stateDashboard = contextDashboard[0];
  const dispatchDashboard = contextDashboard[1];
  const contextTenants = useTenants();
  const stateTenants = contextTenants[0];
  const { selectedTenantId } = stateTenants;
  const { newNetwork } = stateDashboard;
  const {
    page,
    nameError,
    clusterError,
    dataTestIds,
    handleNameError,
    handleClusterError,
  } = props;

  const handleChangeName = (event: TEvent ) => {
    const { name, value } = event.target;

    handleNameError('');
    handleClusterError('');
    dispatchDashboard(
      setNewNetwork({
        ...newNetwork,
        tenant_uuid: selectedTenantId,
        [name]: name === 'cluster_uuid' ? value : value.trim(),
      }),
    );
  };

  const handleChangeCluster = (value: string, uuid: string ) => {
    setSelectedClusterName(value);
    handleNameError('');
    handleClusterError('');
    dispatchDashboard(
      setNewNetwork({
        ...newNetwork,
        tenant_uuid: selectedTenantId,
        cluster_uuid: uuid,
      }),
    );
  };

  return (
    <>
      <div className='col-span-6'>
        <CustomInput
          name='network_name'
          placeholder='Name'
          type='text'
          handleChange={handleChangeName}
          inputFeedback={nameError}
          inputStatus='validation'
          label='Network Name'
          value={newNetwork.network_name}
          testDataId={dataTestIds.inputText}
        />
      </div>
      <div className='mt-10'>
        {page === 'New virtual machine' ?
          <AlertSection /> :
          <div className='col-span-6'>
            <CustomSelect
              handleChange={handleChangeCluster}
              name='cluster_uuid'
              label='Cluster'
              value={selectedClusterName}
              selectFeedback={clusterError}
              options={clusters}
              optionValue='name'
              disabled={clusters.length === 0 ? true : false}
              dataTestId={dataTestIds.inputSelect}
            />
          </div>
        }
      </div>
    </>
  );
};
