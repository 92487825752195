import React from 'react';

import Buttons from './components/Buttons';
import UserInfo from './components/UserInfo';

import classes from '../../index.module.scss';

/**
 * @description PopUpUserData is a component contains user information and buttons.
 *
 * @returns div
 */

export default function PopUpUserData() {
  // style
  const {
    userDataClass,
  } = classes;

  return (
    <div
      data-testid='test-id-main-header-pop-up-user-data'
      className={userDataClass}
    >
      <UserInfo />
      <Buttons />
    </div>
  );
};
