import React, { useReducer, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Security } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import ReactGA from 'react-ga4';

import 'styles/index.scss';

import { initialStateDashboard, reducerDashboard } from 'reducers/dashboard';
import { initialStateTenants, reducerTenants } from 'reducers/tenants';
import { DashboardContext } from 'context/dashboard';
import { TenantsContext } from 'context/tenants';
import Spinner from 'components/spinners/Spinner';
import Routes from 'Routes';

import config from './config';

const oktaAuthData = new OktaAuth(config.oidc);

export default function App() {
  // state
  const [loading, setLoading] = useState(false);

  // reducers
  const [stateTenants, dispatchTenants] = useReducer(reducerTenants, initialStateTenants);
  const [stateDashboard, dispatchDashboard] = useReducer(reducerDashboard, initialStateDashboard);

  // variables
  const history = useHistory();

  // func
  const customAuthHandler = () => {
    history.push('/login');
  };

  const restoreOriginalUri = async (_oktaAuthData: {}, originalUri: string) => {
    setLoading(true);
    history.replace(toRelativeUrl(originalUri || '/tenants', window.location.origin));
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };

  useEffect(() => {
    ReactGA.send(window.location.pathname + window.location.search);
  }, []);

  if(loading && oktaAuthData) {
    return <Spinner />;
  };

  return (
    <div className='bg-white'>
      <Security
        oktaAuth={oktaAuthData}
        onAuthRequired={customAuthHandler}
        restoreOriginalUri={restoreOriginalUri}
      >
        <TenantsContext.Provider value={[stateTenants, dispatchTenants]}>
          <DashboardContext.Provider value={[stateDashboard, dispatchDashboard]}>
            <Routes />
          </DashboardContext.Provider>
        </TenantsContext.Provider>
      </Security>
    </div>
  );
};
