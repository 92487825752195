import { useCallback, useEffect } from 'react';

import { api } from 'helpers/api';
import useHandleFetchRequest from 'hooks/useHandleFetchRequest';
import { IVm } from 'types';
import { useTenants } from 'context/tenants';
import { setSelectedInstanceType } from 'actions/dashboard';
import { useDashboard } from 'context/dashboard';

/**
 * @description useMakeRequestGetVmsByClick - a hook that returns a function
 * that makes an API /GET request to get all instances of type virtualmachine
 * @returns handleGetVms
 */

function useMakeRequestGetVmsByClick() {
  // variables
  const contextTenants = useTenants();
  const stateTenants = contextTenants[0];
  const { selectedTenantId } = stateTenants;
  const page = 'virtualmachine';
  const url = `vm/vms/${selectedTenantId}?mtype=virtualmachine`;
  const fetchMethod = 'get';
  const handleFetchRequest = useHandleFetchRequest();
  const context = useDashboard();
  const dispatch = context[1];

  const handleGetVms = useCallback(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    if (selectedTenantId) {
      const getVms = api.useFetchData<[], Array<IVm>>(url, fetchMethod, signal);
      handleFetchRequest(page, fetchMethod, getVms);
      dispatch(setSelectedInstanceType('virtualmachine'));
    }
    return () => {
      controller.abort();
    };
  }, [handleFetchRequest, selectedTenantId, url, dispatch]);

  return { handleGetVms };
};

/**
 * @description useMakeRequestGetVmsImmediately - a hook that makes an API /GET request
 * to immediately get all instances of type virtualmachine using useEffect
 * @returns null
 */

function useMakeRequestGetVmsImmediately() {
  // variables
  const contextTenants = useTenants();
  const stateTenants = contextTenants[0];
  const { selectedTenantId } = stateTenants;
  const { handleGetVms } = useMakeRequestGetVmsByClick();

  useEffect(() => {
    if (selectedTenantId) {
      handleGetVms();
    }
    return () => {};
  }, [selectedTenantId, handleGetVms]);
};

export {
  useMakeRequestGetVmsByClick,
  useMakeRequestGetVmsImmediately,
};
