import React, { useEffect, useState } from 'react';

import { useInstance } from 'context/instance';
import { useDashboard } from 'context/dashboard';
import { setInstance } from 'actions/instance';
import { IIpv4 } from 'types';
import Button from 'components/buttons';
import PortalModal from 'portals/Modal';
import FormModalTemplate from 'layouts/FormModalTemplate';
import { TDataTestIdsNewVmMainIps } from 'data-test-ids/types/new-vm';
import { useTenants } from 'context/tenants';

import Ipv6Section from './Ipv6Section';
import AddIP4Modal from './AddIP4';
import SelectedIps from './SelectedIps';

import TitleSection from '../TitleSection';

type TProps = {
  dataTestIds: TDataTestIdsNewVmMainIps,
};

/**
 *
 * @param props dataTestIds
 * @param dataTestIds required
 * @returns
 */
export default function Ips(props: TProps) {
  // state
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [warning, setWarning] = useState<string>('');

  // props
  const { dataTestIds } = props;

  // variables
  const contextInstance = useInstance();
  const stateInstance = contextInstance[0];
  const dispatchInstance = contextInstance[1];
  const {
    instance: {
      ips: ipAddresses,
      selectedIpv4,
      preselectedIpv4s,
    },
  } = stateInstance;

  const contextDashboard = useDashboard();
  const stateDashboard = contextDashboard[0];
  const { fetchFunc } = stateDashboard;
  const { data } = fetchFunc.ips.get;
  const testIds = dataTestIds.wrapper;
  const contextTenants = useTenants();
  const stateTenants = contextTenants[0];
  const { selectedTenantId } = stateTenants;

  useEffect(() => {
    if (selectedIpv4) {
      setWarning('');
    }
  }, [selectedIpv4]);

  useEffect(() => {
    if (selectedTenantId) {
      dispatchInstance(setInstance([], 'ips'));
    }
  }, [selectedTenantId, dispatchInstance]);

  const handleAddIpv4 = () => {
    if (!selectedIpv4) {
      return setWarning('*Please select Ip');
    };
    const address = data.filter((address: IIpv4) => address.address4 === selectedIpv4).flat();
    const currentAddress = ipAddresses.filter((address: IIpv4)  => address.address4 === selectedIpv4);
    const ipv4s = [...ipAddresses, address].flat();

    if (currentAddress.length === 0) {
      dispatchInstance(setInstance(ipv4s, 'ips'));
    };
    dispatchInstance(setInstance([...preselectedIpv4s, selectedIpv4], 'preselectedIpv4s'));

    setOpenModal(false);
    setWarning('');
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setWarning('');
  };

  return (
    <div
      className='col-span-6 mt-5'
      data-testid={testIds.name}
    >
      <TitleSection
        title='IP Addresses'
        dataTestId={testIds.title}
      />
      <Ipv6Section
        dataTestId={testIds.ipv6}
      />
      <SelectedIps dataTestIds={testIds.selectedIps} />
      <div
        className='flex justify-end items-center relative'
        data-testid={testIds.addIpv4Wrapper.name}
      >
        <Button
          type='button'
          handleClick={() => setOpenModal(true)}
          uiStyle='small-secondary'
          title='Add IPv4 Address'
          dataTestId={testIds.addIpv4Wrapper.button}
          customStyle='absolute right-0 top-[-10px]'
        />
        {openModal &&
          <PortalModal
            open={openModal}
            onCancel={handleCloseModal}
            dataTestId={testIds.addIpv4Wrapper.modalForm.portal.portalModal.name}
          >
            <FormModalTemplate
              handleSubmitButtonName='Add'
              onCancel={handleCloseModal}
              handleSubmit={handleAddIpv4}
              header='Add IPv4 Address'
              dataTestIds={testIds.addIpv4Wrapper.modalForm.portal.portalModal.form}
            >
              <div
                data-testid={testIds.addIpv4Wrapper.modalForm.warningWrapper.name}
                className='pt-6 px-8 pb-[61px]'
              >
                <AddIP4Modal
                  isEmptySelectIp={warning}
                  dataTestIds={testIds.addIpv4Wrapper.modalForm.warningWrapper.addIpv4ModalForm}
                />
              </div>
            </FormModalTemplate>
          </PortalModal>
        }
      </div>
    </div>
  );
};
