import React, { Suspense } from 'react';
import { useParams } from 'react-router-dom';

import HeaderWithBreadcrumbs from 'components/HeaderWithBreadcrumbs';
import Info  from 'components/Info';
import { DetailsPageLayoutContext } from 'context/detailsPageLayout';
import { TDataTestIdsOverviewPage } from 'data-test-ids/types/overview-page';
import { useTenants } from 'context/tenants';
import { SpinnerLazyLoading } from 'components/spinners/SpinnerLazyLoading';

import MainSectionNetwork from './network/main-section';
import MainSectionInstance from './instance/main-section';
import MainSectionIp from './ip/main-section';

export type TProps = {
  dataTestIds: TDataTestIdsOverviewPage,
  routeNav: string,
  detailsPageName: 'instance' | 'network' | 'ip',
};

export type TDetailsPageContext = Omit<TProps, 'dataTestIds'>

/**
 * @description The DetailsPageLayout component
 * @returns ReactFragment
*/

export default function DetailsPageLayout(props: TProps) {
  // props
  const {
    detailsPageName,
    routeNav,
    dataTestIds,
  } = props;

  // variables
  const { tenantId } = useParams<{tenantId?: ''}>();
  const contextTenants = useTenants();
  const stateTenants = contextTenants[0];
  const { selectedTenantId } = stateTenants;
  const { info, content } = dataTestIds;

  const DetailsPageLayoutContextValue: TDetailsPageContext = {
    detailsPageName,
    routeNav,
  };

  const renderNameRouteForHeader = () => {
    if (detailsPageName === 'network') return 'Networks';
    if (detailsPageName === 'instance') return 'Instances';
    if (detailsPageName === 'ip') return 'Ipv4s';
    return 'your path';
  };

  const renderContent = () => {
    if (detailsPageName === 'network') return <MainSectionNetwork dataTestIds={content.main} />;
    if (detailsPageName === 'instance') return <MainSectionInstance dataTestIds={content.main} />;
    if (detailsPageName === 'ip') return <MainSectionIp dataTestIds={content.main} />;
    return <></>;
  };

  if (selectedTenantId !== tenantId) {
    return (
      <Suspense fallback={<SpinnerLazyLoading />}>
        <Info
          status='warning'
          msg={`This ${detailsPageName} belongs to the tenant with UUID: ${selectedTenantId}!`}
          dataTestId={info}
        />
      </Suspense>
    );
  };

  return (
    <DetailsPageLayoutContext.Provider value={DetailsPageLayoutContextValue}>
      <HeaderWithBreadcrumbs
        route={routeNav}
        routeName={renderNameRouteForHeader()}
        dataTestIds={content.header}
      />
      {renderContent()}
    </DetailsPageLayoutContext.Provider>
  );
};

