// import GpuIcon from 'assets/icons/overview-page/gpu.svg';
import { useDashboard } from 'context/dashboard';
import IconInstances from 'assets/icons/overview-page/vms.svg';
import IconNetworks from 'assets/icons/overview-page/networks.svg';
import IconIpv4s from 'assets/icons/overview-page/ipv4s.svg';

import { useGetRoutesPagePath } from './useGetRoutesPagePath';
import { useMakeRequestGetInstancesImmediately } from './useGetInstances';
import { useMakeRequestGetNetworksImmediately } from './useGetNetworks';
import { useMakeRequestGetIpv4sImmediately } from './useGetIps';

export type TItem = {
  itemIcon: string,
  title: string,
  number: number | 'loading',
  describe: string,
  path: string,
  id: number,
};

export function useItemsOverview(){
  // hooks
  useMakeRequestGetInstancesImmediately();
  useMakeRequestGetNetworksImmediately();
  useMakeRequestGetIpv4sImmediately();

  // variables
  const { handlePagePaths } = useGetRoutesPagePath();
  const contextDashboard = useDashboard();
  const stateDashboard = contextDashboard[0];
  const { fetchFunc } = stateDashboard;
  const instances = fetchFunc.instances.get;
  const networks = fetchFunc.networks.get;
  const ipv4s = fetchFunc.ips.get;

  const ITEMS: TItem[] = [
    {
      itemIcon: IconInstances,
      title: 'Instances',
      number: instances?.loading ? 'loading' : instances?.data.length,
      describe: 'Number of created instances',
      path: handlePagePaths().instances,
      id: 0,
    },
    {
      itemIcon: IconNetworks,
      title: 'Networks',
      number: networks?.loading ? 'loading' : networks?.data.length,
      describe: 'Number of created networks',
      path: handlePagePaths().networks,
      id: 1,
    },
    {
      itemIcon: IconIpv4s,
      title: 'Ipv4s',
      number: ipv4s?.loading ? 'loading' : ipv4s?.data.length,
      describe: 'Number of created ipv4s',
      path: handlePagePaths().ipv4s,
      id: 2,
    },
    // {
    //   icon: GpuIcon,
    //   title: 'GPU usage',
    //   number: 7,
    //   describe: 'Number of used GPUs',
    //   path: '#',
    //   id: 1,
    // },
  ];

  return {ITEMS};
}
