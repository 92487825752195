import { useRef, useState } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid';

import { setInstance } from 'actions/instance';
import { useInstance } from 'context/instance';
import useOnClickOutside from 'hooks/useClickOutside';
import { TVersion, TImage } from 'types';

import classes from './image.module.scss';

type TProps = {
  imagesVersions: TVersion[],
  image: TImage,
  selectedImageId: number,
  handleImage: (id: number) => void,
};

const Dropdown = (props: TProps) => {
  const [openDropdown, setOpenDropdown] = useState(false);

  const ref = useRef(null);

  const { image, imagesVersions, selectedImageId, handleImage } = props;

  const context = useInstance();
  const dispatch = context[1];
  const state = context[0];
  const { instance: { image: versionOfImage } } = state;

  const {
    selectItemClass,
    selectedVersionClass,
    dropdownItemClass,
    dropdownBodyClass,
    openClass,
    dropdownDefaultValueClass,
    dropdownBodyMultyClass,
  } = classes;

  const handleClickOutside = () => {
    setOpenDropdown(false);
  };

  useOnClickOutside(ref, handleClickOutside);

  const handleChooseVesionOfImage = (id: number, vertionsUuid: string) => {
    const imageObject: TVersion | {} = imagesVersions.find((version: TVersion) => version.uuid === vertionsUuid) || {};
    dispatch(setInstance(imageObject, 'image'));
    setOpenDropdown(false);
  };

  const handleOpenDropdown = (id: number) => {
    handleImage(id);
    setOpenDropdown(true);
  };

  const activeStyle = versionOfImage.uuid !== '' && image.name === versionOfImage?.image;
  const dropDownValue = activeStyle ? versionOfImage.name : 'Version';

  return (
    <>
      <button
        type='button'
        className={selectItemClass}
        onClick={() => handleOpenDropdown(image.id)}
      >
        <p className={`truncate w-44 text-start ${!activeStyle ? dropdownDefaultValueClass : null}`}>
          {dropDownValue}
        </p>
        {openDropdown && image.id === selectedImageId ?
          <ChevronUpIcon className={`w-5 h-5 ${openDropdown ? openClass : ''}`}/> :
          <ChevronDownIcon className={`w-5 h-5 ${openDropdown ? openClass : ''}`}/>
        }
      </button>
      {openDropdown && image.id === selectedImageId &&
        <div ref={ref} className={`${dropdownBodyClass} ${imagesVersions.length > 1 ? dropdownBodyMultyClass : ''} ${openDropdown && openClass} z-10`}>
          {imagesVersions.map((version: TVersion) => (
            <div
              key={version.uuid}
              className={`${versionOfImage.uuid === version.uuid ? selectedVersionClass : ''} ${dropdownItemClass} ${imagesVersions.length === 1 ? 'rounded-md' : ''}`}
              onClick={versionOfImage.uuid === version.uuid ? () => setOpenDropdown(false) : () => handleChooseVesionOfImage(image.id, version.uuid)}
              data-testid={version.uuid}
            >
              {version.name}
            </div>
          ))}
        </div>
      }
    </>
  );
};

export default Dropdown;
