import { IAction, IDashboardReducer, IIpv4, INetwork, ISshKey } from 'types';
import { IVm } from 'types';

export const SET_CURRENT_TENANT = 'DASHBOARD.SET_CURRENT_TENANT';
export const SET_NETWORKS = 'DASHBOARD.SET_NETWORKS';
export const SET_NEW_NETWORK = 'DASHBOARD.SET_NEW_NETWORK';
export const SET_SSH_KEYS = 'DASHBOARD.SET_SSH_KEYS';
export const SET_NEW_SSH_KEY = 'DASHBOARD.SET_NEW_SSH_KEY';
export const SET_IPS = 'DASHBOARD.SET_IPS';
export const SET_NEW_IPV4 = 'DASHBOARD.SET_NEW_IPV4';
export const SET_ERROR_HANDLING = 'DASHBOARD.SET_ERROR_HANDLING';
export const SET_MAINTENANCE = 'DASHBOARD.SET_MAINTENANCE';
export const SET_CURRENT_API_VERSION = 'DASHBOARD.SET_CURRENT_API_VERSION';
export const SET_PRESELECTED_REMOVING_ITEMS = 'DASHBOARD.SET_PRESELECTED_REMOVING_ITEMS';
export const SET_NOTIFICATIONS = 'DASHBOARD.SET_NOTIFICATIONS';
export const SET_IS_API_MAINTENANCE = 'DASHBOARD.SET_IS_API_MAINTENANCE';
export const SET_SELECTED_INSTANCE_TYPE = 'DASHBOARD.SET_SELECTED_INSTANCE_TYPE';

export const MAKE_API_REQUEST = 'DASHBOARD.MAKE_API_REQUEST';

export const defaultIp: IIpv4 = {
  vm_uuid: '',
  tenant_uuid: '',
  cluster_uuid: '',
  uuid: '',
  address4: '',
  isSelected: false,
  timeoutShow: 100,
  timeoutClose: 1000,
};

export const defaultSshKey: ISshKey = {
  name: '',
  key: '',
  uuid: '',
  isSelected: false,
  timeoutShow: 100,
  timeoutClose: 1000,
};

export const defaultNetwork: INetwork = {
  tenant_uuid: '',
  cluster_uuid: '',
  network_name: '',
  uuid: '',
  prefix6: '',
  assigned_addresses6: [
    {
      vm_uuid: '',
      address6: '',
    },
  ],
  isSelected: false,
  loading: false,
  statusResponse: 0,
  message: '',
  error: '',
  timeoutShow: 100,
  timeoutClose: 1000,
};

const vm: IVm = {
  uuid: '',
  name: '',
  status: 'VM_ACTIVE',
  flavor_uuid: '',
  network_uuid: '',
  address6: {
    vm_uuid: '',
    address6: '',
  },
  addresses4: [
    {
      uuid: '',
      tenant_uuid: '',
      cluster_uuid: '',
      address4: '',
      vm_uuid: '',
      isSelected: false,
      timeoutShow: 100,
      timeoutClose: 1000,
    },
  ],
  cluster_uuid: '',
  tenant_uuid: '',
  sshkeys: [''],
  image_uuid: '',
  isSelected: false,
};

export const initialStateDashboard: IDashboardReducer = {
  instanceName: 'Virtual Machine',
  version: 'default',
  networks: [],
  newNetwork: {
    network_name: '',
    cluster_uuid: '',
    tenant_uuid: '',
  },
  sshKey: {
    tenant_uuid: '',
    name: '',
    key: '',
  },
  sshKeys: [],
  ip: {
    tenant_uuid: '',
    cluster_uuid: '',
  },
  ips: [],
  maintenance: {
    scheduled: [],
    maintenance: [],
    incidents: [],
  },
  isApiMaintenance: 'default',
  notifi: {
    success: [],
    errors: [],
  },
  preselectedRemovingItems: {
    virtualmachine: {
      uuid: [],
      name: [],
    },
    networks: {
      uuid: [],
      name: [],
    },
    sshKeys: {
      uuid: [],
      name: [],
    },
    ips: {
      uuid: [],
      name: [],
    },
    network: {
      uuid: [],
      name: [],
    },
    // vm: {
    //   uuid: [],
    //   name: [],
    // },
    ip: {
      uuid: [],
      name: [],
    },
    sshKey: {
      uuid: [],
      name: [],
    },
    instances: {
      uuid: [],
      name: [],
    },
    instance: {
      uuid: [],
      name: [],
    },
    baremetal: {
      uuid: [],
      name: [],
    },
  },
  fetchFunc: {
    ip: {
      get: {
        error: '',
        status: 0,
        loading: false,
        message: '',
        data: defaultIp,
      },
      post: {
        error: '',
        status: 0,
        loading: false,
        message: '',
        data: defaultIp,
      },
      patch: {
        error: '',
        status: 0,
        loading: false,
        message: '',
        data: defaultIp,
      },
      delete: {
        error: '',
        status: 0,
        loading: false,
        message: '',
        data: defaultIp,
      },
    },
    ips: {
      get: {
        error: '',
        status: 0,
        loading: false,
        message: '',
        data: [],
      },
      post: {
        error: '',
        status: 0,
        loading: false,
        message: '',
        data: [],
      },
      patch: {
        error: '',
        status: 0,
        loading: false,
        message: '',
        data: [],
      },
      delete: {
        error: '',
        status: 0,
        loading: false,
        message: '',
        data: [],
      },
    },
    sshKeys: {
      get: {
        error: '',
        status: 0,
        loading: false,
        message: '',
        data: [],
      },
      post: {
        error: '',
        status: 0,
        loading: false,
        message: '',
        data: [],
      },
      patch: {
        error: '',
        status: 0,
        loading: false,
        message: '',
        data: [],
      },
      delete: {
        error: '',
        status: 0,
        loading: false,
        message: '',
        data: [],
      },
    },
    sshKey: {
      get: {
        error: '',
        status: 0,
        loading: false,
        message: '',
        data: defaultSshKey,
      },
      post: {
        error: '',
        status: 0,
        loading: false,
        message: '',
        data: defaultSshKey,
      },
      patch: {
        error: '',
        status: 0,
        loading: false,
        message: '',
        data: defaultSshKey,
      },
      delete: {
        error: '',
        status: 0,
        loading: false,
        message: '',
        data: defaultSshKey,
      },
    },
    networks: {
      get: {
        error: '',
        status: 0,
        loading: false,
        message: '',
        data: [defaultNetwork],
      },
      post: {
        error: '',
        status: 0,
        loading: false,
        message: '',
        data: [defaultNetwork],
      },
      patch: {
        error: '',
        status: 0,
        loading: false,
        message: '',
        data: [],
      },
      delete: {
        error: '',
        status: 0,
        loading: false,
        message: '',
        data: [],
      },
    },
    network: {
      get: {
        error: '',
        status: 0,
        loading: false,
        message: '',
        data: defaultNetwork,
      },
      patch: {
        error: '',
        status: 0,
        loading: false,
        message: '',
        data: defaultNetwork,
      },
      post: {
        error: '',
        status: 0,
        loading: false,
        message: '',
        data: defaultNetwork,
      },
      delete: {
        error: '',
        status: 0,
        loading: false,
        message: '',
        data: defaultNetwork,
      },
    },
    virtualmachine: {
      get: {
        error: '',
        status: 0,
        loading: false,
        message: '',
        data: [],
      },
      post: {
        error: '',
        status: 0,
        loading: false,
        message: '',
        data: [],
      },
      patch: {
        error: '',
        status: 0,
        loading: false,
        message: '',
        data: [],
      },
      delete: {
        error: '',
        status: 0,
        loading: false,
        message: '',
        data: [],
      },
    },
    // vm: {
    //   get: {
    //     error: '',
    //     status: 0,
    //     loading: false,
    //     message: '',
    //     data: vm,
    //   },
    //   post: {
    //     error: '',
    //     status: 0,
    //     loading: false,
    //     message: '',
    //     data: vm,
    //   },
    //   patch: {
    //     error: '',
    //     status: 0,
    //     loading: false,
    //     message: '',
    //     data: vm,
    //   },
    //   delete: {
    //     error: '',
    //     status: 0,
    //     loading: false,
    //     message: '',
    //     data: vm,
    //   },
    // },
    instance: {
      get: {
        error: '',
        status: 0,
        loading: false,
        message: '',
        data: vm,
      },
      post: {
        error: '',
        status: 0,
        loading: false,
        message: '',
        data: vm,
      },
      patch: {
        error: '',
        status: 0,
        loading: false,
        message: '',
        data: vm,
      },
      delete: {
        error: '',
        status: 0,
        loading: false,
        message: '',
        data: vm,
      },
    },
    instances: {
      get: {
        error: '',
        status: 0,
        loading: false,
        message: '',
        data: [],
      },
      post: {
        error: '',
        status: 0,
        loading: false,
        message: '',
        data: [],
      },
      patch: {
        error: '',
        status: 0,
        loading: false,
        message: '',
        data: [],
      },
      delete: {
        error: '',
        status: 0,
        loading: false,
        message: '',
        data: [],
      },
    },
    baremetal: { // berametal
      get: {
        error: '',
        status: 0,
        loading: false,
        message: '',
        data: [],
      },
      post: {
        error: '',
        status: 0,
        loading: false,
        message: '',
        data: [],
      },
      patch: {
        error: '',
        status: 0,
        loading: false,
        message: '',
        data: [],
      },
      delete: {
        error: '',
        status: 0,
        loading: false,
        message: '',
        data: [],
      },
    },
  },
  request: {
    status: 0,
    pending: false,
    message: '',
    data: [],
  },
  selectedInstanceType: 'virtualmachine',
};

export function reducerDashboard(state: IDashboardReducer, action: IAction): IDashboardReducer {
  switch (action.type) {
  case SET_NETWORKS:
    return {
      ...state,
      networks: action.networks,
    };
  case SET_NEW_NETWORK:
    return {
      ...state,
      newNetwork: action.newNetwork,
    };
  case SET_NEW_SSH_KEY:
    return {
      ...state,
      sshKey: action.sshKey,
    };
  case SET_SSH_KEYS:
    return {
      ...state,
      sshKeys: action.sshKeys,
    };
  case SET_NEW_IPV4:
    return {
      ...state,
      ip: action.ip,
    };
  case SET_IPS:
    return {
      ...state,
      ips: action.ips,
    };
  case SET_MAINTENANCE:
    return {
      ...state,
      maintenance: action.maintenance,
    };
  case SET_IS_API_MAINTENANCE:
    return {
      ...state,
      isApiMaintenance: action.isApiMaintenance,
    };

  case SET_NOTIFICATIONS:
    return {
      ...state,
      notifi: {
        ...state.notifi,
        [action.notifiKey]: action.notifi,
      },
    };
  case SET_ERROR_HANDLING:
    return {
      ...state,
      fetchFunc: {
        ...state.fetchFunc,
        [action.page]: {
          ...state.fetchFunc[action.page],
          [action.fetchMethod]: {
            ...state.fetchFunc[action.page][action.fetchMethod],
            [action.status]: action.fetchFunc,
          },
        },
      },
    };
  case SET_CURRENT_API_VERSION:
    return {
      ...state,
      version: action.version,
    };
  case SET_PRESELECTED_REMOVING_ITEMS:
    return {
      ...state,
      preselectedRemovingItems: {
        ...state.preselectedRemovingItems,
        [action.preselectedRemovingItemsPage]: {
          ...state.preselectedRemovingItems[action.preselectedRemovingItemsPage],
          [action.preselectedRemovingItemValue]: action.preselectedRemovingItems,
        },
      },
    };

  case MAKE_API_REQUEST:
    return {
      ...state,
      request: {
        ...state.request,
        // [action.method]: {
        // ...state.request[action.method],
        [action.state]: action.request,
        // },
      },
    };

  case SET_SELECTED_INSTANCE_TYPE:
    return {
      ...state,
      selectedInstanceType: action.selectedInstanceType,
    };

  default:
    throw new Error(`Unhandled action type: ${action.type}`);
  };
};
