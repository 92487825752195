import { Dispatch, SetStateAction, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { useDashboard } from 'context/dashboard';
import useHandleFetchRequest from 'hooks/useHandleFetchRequest';
import { api } from 'helpers/api';
import { IVm } from 'types';
import PortalModal from 'portals/Modal';
import FormModalTemplate from 'layouts/FormModalTemplate';
import { useCleanState } from 'hooks/useCleanState';
import Spinner from 'components/spinners/Spinner';
import { useGetRoutesPagePath } from 'hooks/useGetRoutesPagePath';

import VmOverviewModalInfo from '../../../components/info-section';

type TProps = {
  vmUUID: string,
  open: boolean,
  handleOpenModal: Dispatch<SetStateAction<boolean>>,
  dataTestIds?: {},
};

/**
 * @description The VmOverviewModal component
 * @param props vmUUID, open, handleOpenModal
 * @param vmUUID required
 * @param open required
 * @param handleOpenModal required
 *
 * @returns Portal modal form
*/

export default function VmOverviewModal(props: TProps) {
  // props
  const {
    vmUUID = '',
    open = false,
    handleOpenModal = () => {},
    // dataTestIds = {},
  } = props;

  // variables
  const contextDashboard = useDashboard();
  const state = contextDashboard[0];
  const { fetchFunc: { instance: { get: { data: { name }, status } } } } = state;
  const url = `vms/vm/${vmUUID}`;
  const handleFetchRequest = useHandleFetchRequest();
  const history = useHistory();
  const cleanState = useCleanState();
  const { handlePagePaths } = useGetRoutesPagePath();

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    if (vmUUID) {
      const getVm = api.useFetchData<[], IVm>(url, 'get', signal);
      handleFetchRequest('instance', 'get', getVm);
    }
    return () => {
      controller.abort();
    };
  }, [url, vmUUID, handleFetchRequest]);

  // func
  const redirectToVm = (path: string) => {
    history.push(path);
  };

  const handleCloseModalWindow = () => {
    handleOpenModal(false);
    cleanState('instance', 'get');
  };

  return (
    <PortalModal
      open={open}
      onCancel={handleCloseModalWindow}
      width={status === 200 ? 'w-auto' : 'w-[1090px]' }
      // dataTestId={dataTestIds}
    >
      <FormModalTemplate
        handleSubmit={() => redirectToVm(handlePagePaths(vmUUID).instance)}
        handleSubmitButtonName='Go to Instance'
        onCancel={handleCloseModalWindow}
        header={`Instance ${name || ''}`}
        // dataTestIds={dataTestIds}
      >
        {status === 200 ?
          <VmOverviewModalInfo /> :
          <Spinner />
        }
      </FormModalTemplate>
    </PortalModal>
  );
};
