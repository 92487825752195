import React, { ChangeEvent } from 'react';

import Table from 'components/table-component/Table';
import BodyRow from 'components/table-component/Cell';
import MenuComponent from 'components/menu';
import Row from 'components/table-component/Row';
import CustomCheckbox from 'components/input/Checkbox';
import { ISshKey } from 'types';
import CopyComponent from 'feature/copy-func';
import { TEST_ID_SSH_KEYS_PAGE_COPY_TOOLTIP } from 'data-test-ids/data-testid-variables';

type TProps = {
  handleSelectItems: (
    event: ChangeEvent<HTMLInputElement>,
    uuid: string
  ) => void,
  data: ISshKey[],
  isAllSelected: boolean,
  handleDeselectItems: (event: ChangeEvent<HTMLInputElement>) => void,
  isShowCheckbox: boolean,
  handleSelectedAllItems: () => void,
  dataTestId: string,
};

export default function SSHKeysTableComponent(props: TProps) {
  // props
  const {
    handleSelectItems,
    data,
    isAllSelected,
    handleDeselectItems,
    isShowCheckbox,
    handleSelectedAllItems,
    dataTestId,
  } = props;

  // variables
  const headData = ['Name', 'Key'];

  return (
    <Table
      isAllSelected={isAllSelected}
      headData={headData}
      handleDeselectItems={handleDeselectItems}
      isShowCheckbox={isShowCheckbox}
      handleSelectedAllItems={handleSelectedAllItems}
      dataTestId={dataTestId}
    >
      <tbody className='divide-y divide-gray-200 bg-white'>
        {data?.map((sshkey, index) => {
          const element = (number: number) => sshkey.uuid === data.at(number)?.uuid;
          const lastElement = element(-1) || element(-2) ? true : false;
          return (
            <Row key={sshkey.uuid}>
              <BodyRow>
                <div className='mx-6 flex'>
                  <CustomCheckbox
                    type='checkbox'
                    name='isSelected'
                    handleChange={(event) => handleSelectItems(event, sshkey.uuid)}
                    value={sshkey.uuid}
                    aria-label='isSelected'
                    isSelected={sshkey.isSelected || false}
                    disabled={false}
                  />
                </div>
              </BodyRow>
              <BodyRow>
                <div className='truncate w-56'>
                  {sshkey.name}
                </div>
              </BodyRow>
              <BodyRow>
                <div className='row'>
                  <div className='truncate w-[613px] text-customColorDarkBlue60'>
                    {sshkey.key}
                  </div>
                  <CopyComponent
                    value={sshkey.key}
                    // dataTestId='ssh_key_tooltip'
                    dataTestId={TEST_ID_SSH_KEYS_PAGE_COPY_TOOLTIP} //'ssh_key_tooltip'
                  />
                </div>
              </BodyRow>
              <BodyRow>
                <MenuComponent
                  uuid={sshkey.uuid}
                  lastElement={lastElement}
                  url='sshkeys/sshkey'
                  route=''
                  actions={['DELETED']}
                  page='sshKeys'
                  fetchMethod='delete'
                  itemName={sshkey.name}
                >
                </MenuComponent>
              </BodyRow>
            </Row>
          );
        })}
      </tbody>
    </Table>
  );
};
