import React, { useEffect } from 'react';

import { useDashboard } from 'context/dashboard';
import Button from 'components/buttons';
import { IIpv4, INetwork, ISshKey } from 'types';
import useCorrectNameForBtn from 'hooks/useCorrectNameForBtn';
import { setNotifi, setPreselectedRemovingItems } from 'actions/dashboard';
import { IVm } from 'types';
import {
  TEST_ID_BULK_OPERATIONS_CLEAR_ALL_ITEMS_BTN,
  TEST_ID_BULK_OPERATIONS_DELETE_ALL_ITEMS_BTN,
} from 'data-test-ids/data-testid-variables';

type TProps = {
  setOpenPortalModalWindow: (value: boolean) => void;
  page: 'ips' | 'sshKeys' | 'virtualmachine' | 'networks' | 'instances' | 'baremetal',
  handleDeselectedAllItems: () => void,
};

type TData = (IVm | IIpv4 | INetwork | ISshKey)[];

export default function BulkOperationsSection(props: TProps) {
  // props
  const { setOpenPortalModalWindow, page, handleDeselectedAllItems } = props;

  // variables
  const context = useDashboard();
  const state = context[0];
  const dispatch = context[1];
  const { fetchFunc, preselectedRemovingItems } = state;
  const { data }= fetchFunc[page].get;
  const dataWithCustomValues: TData = data && data.map(
    (item: IIpv4 | IVm | INetwork | ISshKey) => (
      {
        ...item,
        isSelected: item.isSelected || false,
        loading: false,
        statusResponse: 0,
        message: '',
        error: '',
        id: item.uuid,
      }
    ),
  );
  const howManyItemsSelected = preselectedRemovingItems[page].uuid.length;
  const { nameForActionBtn } = useCorrectNameForBtn(page, dataWithCustomValues);

  // func
  useEffect(() => {
    return () => {
      dispatch(setPreselectedRemovingItems(page, 'uuid', []));
      dispatch(setPreselectedRemovingItems(page, 'name', []));
    };
  }, [dispatch, page]);

  const handleRemoveItemsModal = () => {
    dispatch(setNotifi('errors', []));
    dispatch(setNotifi('success', []));
    setOpenPortalModalWindow(true);
  };

  return (
    <div className='row'>
      <Button
        uiStyle='gray-small'
        handleClick={handleRemoveItemsModal}
        disabled={howManyItemsSelected=== 0 ? true : false}
        dataTestId={TEST_ID_BULK_OPERATIONS_DELETE_ALL_ITEMS_BTN} //'delete_all'
      >
        Delete {nameForActionBtn}
      </Button>
      {howManyItemsSelected !== 0 &&
        <Button
          uiStyle='only-text'
          handleClick={handleDeselectedAllItems}
          disabled={howManyItemsSelected=== 0 ? true : false}
          dataTestId={TEST_ID_BULK_OPERATIONS_CLEAR_ALL_ITEMS_BTN} //'clear_all'
        >
          Clear all
        </Button>
      }
    </div>
  );
};
