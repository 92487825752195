import React from 'react';

import { useDashboard } from 'context/dashboard';
import { INotification, TFetchFuncPage, TFetchFuncRequestMethod } from 'types';
import getVMsStatus, { TActionsMenuComponent } from 'helpers/getStyleVMStatus';
import Info from 'components/Info';
import Spinner from 'components/spinners/Spinner';


type TProps = {
  actionName: TActionsMenuComponent,
  itemsName: 'ip' | 'network' | 'virtual machine' | 'ssh key' | 'Virtual Machine' | 'Instance',
  page: TFetchFuncPage,
  fetchMethod: TFetchFuncRequestMethod,
  numberOfItems: number | string,
  errorsObject: INotification[],
  successObject: INotification[],
};

export default function ContentViewMultyItems(props: TProps) {
  const {
    actionName,
    fetchMethod,
    page,
    numberOfItems,
    errorsObject,
    successObject,
    itemsName,
  } = props;

  const context = useDashboard();
  const state = context[0];
  const { fetchFunc } = state;
  const { loading } = fetchFunc[page][fetchMethod];

  if (loading) {
    return  <Spinner spaceAroundSpinner />;
  };

  const successMsg = `Successfully ${getVMsStatus(actionName).past} ${successObject.length} ${itemsName}(s)`;

  return (
    <div className='px-5 py-4'>
      {(successObject.length === 0 && errorsObject.length === 0) &&
        <p className='pt-6 pl-1 text-sm font-medium pb-[10px]'>
          Do you really want to {getVMsStatus(actionName).lowercase} {numberOfItems} selected {itemsName}(s)?
        </p>
      }
      {successObject.length !== 0 && <Info status='success' msg={successMsg} />}
      {errorsObject.length !== 0 &&
        <>
          <p className={`m-1 text-sm ${successObject.length === 0 ? '' : 'pt-7'} pb-7 font-medium`}>
            The following {errorsObject.length} {itemsName}(s) cannot be {getVMsStatus(actionName).past}:
          </p>
          <div className='max-h-[27rem] overflow-y-scroll'>
            {errorsObject.map((item: INotification) =>
              <div
                className='mb-2'
                key={item.message}
              >
                <Info
                  status='error'
                  msg={item.message}
                />
              </div>,
            )}
          </div>
        </>
      }
    </div>
  );
};
