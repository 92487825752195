import { useInstance } from 'context/instance';
import React from 'react';
import { TImage } from 'types';

import Dropdown from './Dropdown';
import classes from './image.module.scss';

type TProps = {
  image: TImage,
  selectedImageId: number,
  handleImage: (id: number) => void,
};

const Item = (props: TProps) => {

  const {
    image,
    selectedImageId,
    handleImage,
  } = props;

  const { itemClass } = classes;
  const context = useInstance();
  const state = context[0];
  const { instance: { image: versionOfImage } } = state;
  const activeStyle = versionOfImage.uuid !== '' && image.name === versionOfImage?.image;

  return (
    <div
      className={[
        itemClass,
        activeStyle ?
          'border-customColorLightGreen bg-green-50' :
          'bg-white border-customColorLightGray',
      ].join(' ')}
      data-testid={image.name}
    >
      <div className='col all-items-center mt-[15px] mb-[11px]'>
        <img src={image.logo} alt={image.name} className='w-6 mb-[10px]' />
        <p className='font-medium text-sm text-custom-customColorDarkBlue'>
          {image.name}
        </p>
      </div>
      <Dropdown
        imagesVersions={image.versions}
        image={image}
        selectedImageId={selectedImageId}
        handleImage={handleImage}
      />
    </div>
  );
};

export default Item;
