import React from 'react';

import classes from '../instance.module.scss';

export default function Ipv6Section({dataTestId}: {dataTestId: string}) {
  const { sectionWrapperClass } = classes;

  return (
    <div data-testid={dataTestId} className={`${sectionWrapperClass} justify-start pl-6 bg-[#F9FAFB]`}>
      <p className='mr-6 text-sm font-medium'>IPv6 Address</p>
      <p className='text-customGray3 text-sm font-medium'>(You will receive this address automatically)</p>
    </div>
  );
};
