import React from 'react';

import Typography from 'components/typography';
import getVMsStatus from 'helpers/getStyleVMStatus';
import { TStatusVm, TTextFont } from 'types';

type TProps = TTextFont & {
  status: TStatusVm | string,
};

/**
 *
 * @param props status, fontSize, fontWeight, color
 * @param status required
 * @returns div
 */

export default function StatusVmViewComponent(props: TProps) {
  // props
  const {
    status,
    fontSize='base',
    fontWeight='normal',
    color='customColorDarkBlue',
  } = props;

  // variables
  const statusStyle = getVMsStatus(status);

  return (
    <div className='row items-center justify-start'>
      <div className={`w-[10px] h-[10px] mb-[0.1rem] mr-2 rounded-full ${statusStyle.background}`} />
      <Typography
        text={statusStyle.name}
        fontSize={fontSize}
        fontWeight={fontWeight}
        color={color}
      />
    </div>
  );
};
