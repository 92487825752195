import { useEffect } from 'react';

import { useDashboard } from 'context/dashboard';
import { api } from 'helpers/api';
import useHandleFetchRequest from 'hooks/useHandleFetchRequest';
import { ISshKey } from 'types';
import { useTenants } from 'context/tenants';

export const useGetSshKeys = () => {
  // variables
  const contextDashboard = useDashboard();
  const stateDashboard = contextDashboard[0];
  const contextTenants = useTenants();
  const stateTenants = contextTenants[0];
  const { selectedTenantId } = stateTenants;
  const { fetchFunc } = stateDashboard;
  const { data } = fetchFunc.sshKeys.get;
  const url = `sshkeys/${selectedTenantId}`;
  const handleFetchRequest = useHandleFetchRequest();
  const page = 'sshKeys';

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    if (selectedTenantId) {
      const getNetworks = api.useFetchData<[], Array<ISshKey>>(url, 'get', signal);
      handleFetchRequest(page, 'get', getNetworks);
    }
    return () => {
      controller.abort();
    };
  }, [url, page, selectedTenantId, handleFetchRequest]);

  return { count: data.length };
};

