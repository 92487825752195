import { useLocation } from 'react-router-dom';

import { useDashboard } from 'context/dashboard';
import getClusterName from 'helpers/getClusterName';
import getNameOfFlavor from 'helpers/getNameOfFlavor';
import { matchImageUUID } from 'helpers/MatchUUIDs';

export type TItems = {title: string, value: string}[]

export default function useGetItemsDetailPage() {
  // variables
  const contextDashboard = useDashboard();
  const state = contextDashboard[0];
  const { fetchFunc } = state;
  const { data } = fetchFunc.instance.get;
  const { status, uuid: vmUUID = '', flavor_uuid = '', image_uuid = '', network_uuid = '' } = data;
  const cluster = getClusterName(data.cluster_uuid);
  const location = useLocation();
  const instanceType = localStorage.getItem('selectedInstanceType') || '';

  const showCorrectTypeName = (type: string) => {
    if(type === 'baremetal') {
      return 'Bare Metal';
    };
    if(type === 'virtualmachine') {
      return 'Virtual Machine';
    };
    return '-';
  };

  const infoNetwork = {
    title: 'Network UUID',
    value: data?.network ? data?.network?.network_name : network_uuid,
  };

  const infoStatus = {
    title: 'Status',
    value: status,
  };

  const items: TItems = [
    {
      title: 'UUID',
      value: vmUUID,
    },
    {
      title: 'Flavor',
      value: getNameOfFlavor(flavor_uuid) || '',
    },
    {
      title: 'Cluster',
      value: `${cluster[1]} ${cluster[0]}`,
    },
    {
      title: 'Image',
      value: matchImageUUID(image_uuid) || '',
    },
    {
      title: 'Type',
      value: data?.mtype ? showCorrectTypeName(data?.mtype) : showCorrectTypeName(instanceType),
    },
  ];

  const ITEMS = location.pathname.includes('/instances') ?
    items.toSpliced(1, 0, infoNetwork) : items.toSpliced(1, 0, infoStatus);

  const networkUuid = network_uuid;

  return {ITEMS, networkUuid};
};
