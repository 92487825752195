import React from 'react';
import { NavLink } from 'react-router-dom';

import Button from 'components/buttons';
import useLogout from 'hooks/useLogout';

import classes from '../../../index.module.scss';

/**
 * @description Buttons component is used in the main header
 * in the popup to show the logout and account settings buttons.
 *
 * @returns div
 */

export default function Buttons() {
  // variables
  const { handleLogout } = useLogout();
  const {
    userDataTextBtnWrapperClass,
  } = classes;

  return (
    <div
      data-testid='test-id-main-header-pop-up-buttons'
      className={userDataTextBtnWrapperClass}
    >
      {process.env.REACT_APP_IS_DEMO === 'demo' ?
        <NavLink to='/demo'>
          <p
            className='hover:text-primaryColorEasternBlue'
            data-testid='test-id-main-header-pop-up-buttons-link-okta-demo'
          >
            Manage Account via Okta
          </p>
        </NavLink>
        :
        <a
          className='hover:text-primaryColorEasternBlue'
          rel='noreferrer noopener'
          target='_blank'
          href='https://login.northerndata.de/enduser/settings'
          data-testid='test-id-main-header-pop-up-buttons-link-okta'
        >
          Manage Account via Okta
        </a>
      }
      <Button
        customStyle='mt-2'
        handleClick={handleLogout}
        title='Logout'
        uiStyle='log out'
        dataTestId='test-id-main-header-pop-up-buttons-log-out'
      />
    </div>
  );
};
