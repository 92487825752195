import { useCallback } from 'react';

import { useInstance } from 'context/instance';
import { setInstance, setInstanceValidation, setTab } from 'actions/instance';
import { ITabsItemFlavor, TFlavorTabs } from 'types';

export default function useSelectFlavorTab() {
  // variables
  const context = useInstance();
  const dispatch = context[1];

  // func
  const handleSelectedFlavor = useCallback((item: ITabsItemFlavor ) => {
    const defaultFlavor = { ...item, selected: true};
    dispatch(setInstance(defaultFlavor, 'flavor'));
    dispatch(setInstance('', 'error'));
    dispatch(setInstance(defaultFlavor.cluster, 'selectedCluster'));
    dispatch(setInstanceValidation(false, 'flavor'));
  }, [dispatch]);

  const handleSelectedFlavorTab = useCallback((name: TFlavorTabs, item: ITabsItemFlavor ) => {
    dispatch(setTab(name));
    handleSelectedFlavor(item);
  }, [dispatch, handleSelectedFlavor]);

  return { handleSelectedFlavorTab, handleSelectedFlavor };
};
