import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import IconSpinner from 'assets/icons/IconSpinner';

import classes from './overview.module.scss';

import { TItem } from '../../hooks/useItemsOverview';

type TProps = {
  item: TItem,
};

export default function Item(props: TProps) {
  // state
  const [, setHoverState] = useState<boolean>(false);
  const [hoverElement, setHoverElement] = useState<number | null>(null);

  // props
  const { title, number, describe, path, id, itemIcon } = props.item;

  // variables
  const history = useHistory();
  const { itemTitleClass, itemDescriptionsClass } = classes;

  // func
  const handleHoverStateEnter = (id: number) => {
    setHoverElement(id);
    if (id === hoverElement) {
      setHoverState(true);
    }
  };

  const handleHoverStateLeave = () => {
    setHoverElement(null);
    setHoverState(false);
  };

  return (
    <div
      className={classes.itemClass}
      onClick={() => history.push(path)}
      onMouseEnter={() => handleHoverStateEnter(id)}
      onMouseLeave={handleHoverStateLeave}
    >
      <div className='row items-center'>
        <img src={itemIcon} alt={title} className={classes.iconClass}/>
        <div className='col'>
          <h5 className={itemTitleClass}>{title}</h5>
          <p className={itemDescriptionsClass}>{describe}</p>
        </div>
      </div>
      {number === 'loading' ?
        <IconSpinner customStyle='w-4 h-4' />
        :
        <p className={itemTitleClass}>{number}</p>
      }
    </div>
  );
};
