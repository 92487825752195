import VmsIcon from 'assets/icons/navbar/vms.svg';
import NetworksIcon from 'assets/icons/navbar/networks.svg';
import SshKeysIcon from 'assets/icons/navbar/ssh-keys.svg';
import Ipv4sIcon from 'assets/icons/navbar/ipv4s.svg';
import OverviewIcon from 'assets/icons/navbar/overview.svg';
import { useGetRoutesPagePath } from 'hooks/useGetRoutesPagePath';

type TRoute = {
  path: string,
  title: 'Instances' | 'Networks' | 'SSH Keys' | 'IPv4s' | 'Overview',
  icon: string,
  exact: boolean,
};

/**
 * @description useGetSidebarNavLink - hook
 * @returns nav links for sidebar
 */

export const useGetSidebarNavLinks = () => {
  // variables
  const { handlePagePaths } = useGetRoutesPagePath();
  const navLinks: TRoute[] = [
    {
      path: '/',
      title: 'Overview',
      icon: OverviewIcon,
      exact: true,
    },
    {
      path: handlePagePaths().instances,
      title: 'Instances',
      icon: VmsIcon,
      exact: false,
    },
    {
      path: handlePagePaths().networks,
      title: 'Networks',
      icon: NetworksIcon,
      exact: false,
    },
    {
      path: handlePagePaths().sshKeys,
      title: 'SSH Keys',
      icon: SshKeysIcon,
      exact: false,
    },
    {
      path: handlePagePaths().ipv4s,
      title: 'IPv4s',
      icon: Ipv4sIcon,
      exact: false,
    },
  ];

  return { navLinks };
};
