import React, { ChangeEvent } from 'react';
import { Link } from 'react-router-dom';

import Table from 'components/table-component/Table';
import BodyRow from 'components/table-component/Cell';
import getClusterName from 'helpers/getClusterName';
import getNameOfFlavor from 'helpers/getNameOfFlavor';
import { IVm } from 'types';
import Row from 'components/table-component/Row';
import { matchImageUUID } from 'helpers/MatchUUIDs';
import MenuComponent from 'components/menu';
import CustomCheckbox from 'components/input/Checkbox';
import StatusVmViewComponent from 'components/vms/StatusVmViewComponent';
import { useGetRoutesPagePath } from 'hooks/useGetRoutesPagePath';
import useCheckVmsStatusForDeletion from 'hooks/useCheckVmsStatusForDeletion';
import { getCorrectListOfActions } from 'helpers/getCorrectListOfActions';
import { useDashboard } from 'context/dashboard';

import RowIps from './table-elements/RowIps';

type TProps = {
  handleSelectItems: (event: ChangeEvent<HTMLInputElement>, uuid: string) => void,
  data: IVm[],
  isAllSelected: boolean,
  handleDeselectItems: (event: ChangeEvent<HTMLInputElement>) => void,
  isShowCheckbox: boolean,
  handleSelectedAllItems: () => void,
};

export default function InstancesTableComponent(props: TProps) {
  // props
  const {
    handleSelectItems,
    data,
    isAllSelected,
    handleDeselectItems,
    isShowCheckbox,
    handleSelectedAllItems,
  } = props;

  // variables
  const headData = [
    'Name', 'Flavor', 'Cluster', 'IP Addresses', 'Image', 'Status',
  ];
  const isStatusOkay = useCheckVmsStatusForDeletion();
  const isAllowDeleteVms = (
    data.length === 0 || data.some((vm: IVm) =>
      vm.status === 'VM_ACTIVE' ||
      vm.status === 'POWER_OFF' ||
      vm.status === 'REBOOT_REQUESTED' ||
      vm.status === 'RESET_REQUESTED',
    )
  ) ? false : true;
  const { handlePagePaths } = useGetRoutesPagePath();
  const context = useDashboard();
  const state = context[0];
  const { selectedInstanceType } = state;

  // func
  const handleSetTypeToInstance = (type: 'virtualmachine' | 'baremetal' | '-') => {
    localStorage.setItem('selectedInstanceType', type);
  };

  return (
    <Table
      isAllSelected={isAllSelected}
      handleDeselectItems={handleDeselectItems}
      handleSelectedAllItems={handleSelectedAllItems}
      headData={headData}
      isShowCheckbox={isShowCheckbox}
      disabledCheckbox={isAllowDeleteVms}
    >
      <tbody className='bg-white'>
        {data?.map((vm: IVm, index: number) => {
          const { uuid, status, name, flavor_uuid, cluster_uuid, isSelected } = vm;
          const ipv6 = vm?.address6?.address6;
          const ipv4 = vm?.addresses4 && vm.addresses4[0]?.address4;
          const element = (number: number) => uuid === data.at(number)?.uuid;
          const length = data?.length;
          const lastElement = element(-1) || element(-2) || element(-3) || element(-4) ? true : false;
          const positionMenu = length > 5 ? lastElement : false;
          const isVmDeleted = isStatusOkay(status);

          return (
            <Row key={uuid}>
              {(selectedInstanceType === 'baremetal') ?
                null :
                <BodyRow>
                  <div className='mx-6 flex'>
                    <CustomCheckbox
                      type='checkbox'
                      name='isSelected'
                      handleChange={(event) => handleSelectItems(event, uuid)}
                      value={uuid}
                      aria-label='isSelected'
                      isSelected={isSelected || false}
                      disabled={isVmDeleted ? false : true}
                    />
                  </div>
                </BodyRow>
              }
              <BodyRow>
                <div className={`w-[180px] truncate pr-4 ${(selectedInstanceType === 'baremetal') ? 'pl-6' : ''}`}>
                  <Link
                    to={handlePagePaths(uuid).instance}
                    onClick={() => handleSetTypeToInstance((vm?.mtype ? vm?.mtype : selectedInstanceType) || '-')}
                  >
                    {name}
                  </Link>
                </div>
              </BodyRow>
              <BodyRow>{getNameOfFlavor(flavor_uuid)}</BodyRow>
              <BodyRow>
                <div>{getClusterName(cluster_uuid)[1]}</div>
              </BodyRow>
              <BodyRow>
                {!ipv4 && !ipv6 ?
                  <div className='text-center'> - </div> :
                  <RowIps
                    isShowIpv4={vm?.addresses4?.length !== 0 ? true : false}
                    isIpv4sMoreThan1={vm?.addresses4?.length > 1 ? ', ...' : null}
                    ipv6={ipv6}
                    ipv4={ipv4}
                  />
                }
              </BodyRow>
              <BodyRow>
                {matchImageUUID(vm.image_uuid)}
              </BodyRow>
              <BodyRow>
                <StatusVmViewComponent
                  status={status}
                  fontSize='s'
                  fontWeight='thin'
                  color='customColorDarkBlue60'
                />
              </BodyRow>
              <BodyRow>
                <MenuComponent
                  itemName={name}
                  uuid={uuid}
                  lastElement={positionMenu}
                  actions={
                    (selectedInstanceType === 'baremetal')
                      ? ['view'] :
                      getCorrectListOfActions(status, 'virtualmachine')
                  }
                  route={handlePagePaths(uuid).instance}
                  url='vm/vm'
                  page={selectedInstanceType}
                  fetchMethod='post'
                />
              </BodyRow>
            </Row>
          );
        })}
      </tbody>
    </Table>
  );
};
