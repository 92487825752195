import React from 'react';

import PortalModal from 'portals/Modal';
import { TFetchFuncPage, TFetchFuncRequestMethod } from 'types';
import getVMsStatus, { TActionsMenuComponent } from 'helpers/getStyleVMStatus';
import { useDashboard } from 'context/dashboard';

import Content from './Content';

type TProps =  {
  route?: string,
  uuid: string,
  url: string,
  page: TFetchFuncPage,
  fetchMethod: TFetchFuncRequestMethod,
  actionName: TActionsMenuComponent,
  itemName: string,
  handleCloseModalWindow: () => void,
  openPortalModalWindow: boolean,
};

export default function ActionItemPortalModal(props: TProps) {
  // props
  const {
    uuid,
    url,
    page,
    fetchMethod,
    actionName,
    itemName,
    handleCloseModalWindow,
    openPortalModalWindow,
  } = props;

  // variables
  const contextDashboard = useDashboard();
  const stateDashboard = contextDashboard[0];
  const { instanceName } = stateDashboard;
  const isRedBtn = (actionName === 'DELETED' || actionName === 'POWER_OFF') ? true : false;

  const returnHeaderName = () => {
    let name = '';
    if (page === 'instance' || page === 'instances' || page === 'virtualmachine' || page === 'baremetal') {
      name = instanceName;
    }
    if (page === 'sshKeys') {
      name = 'Ssh Key';
    }
    if (page === 'ips') {
      name = 'Ipv4 Address';
    }
    if (page === 'networks' || page === 'network') {
      name = 'Network';
    }
    return (
      <div className='row'>
        <p>{getVMsStatus(actionName).action} {name}</p>
      </div>
    );
  };

  return (
    <>
      <PortalModal
        open={openPortalModalWindow}
        onCancel={handleCloseModalWindow}
      >
        <Content
          actionName={actionName}
          itemName={itemName}
          onCancel={handleCloseModalWindow}
          redBtn={isRedBtn}
          page={page}
          fetchMethod={fetchMethod}
          uuid={uuid}
          status={getVMsStatus(actionName).status}
          url={url}
          headerModal={returnHeaderName()}
          handleSubmitButtonName={getVMsStatus(actionName).action}
        />
      </PortalModal>
    </>
  );
};
