import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import PageLayout from 'layouts/pages';
import { IVm } from 'types';
import useHandleFetchRequest from 'hooks/useHandleFetchRequest';
import { api } from 'helpers/api';
import DetailsPageLayout from 'layouts/details-page';
import { dataTestIdsVm } from 'data-test-ids';
import { useGetRoutesPagePath } from 'hooks/useGetRoutesPagePath';

import NotFound from './error-statuses/NotFound';

/**
 * @description The Instance component this is a detailed page of a single Instance,
 * sets the title in the browser tab, makes a request to the API

 * @returns ReactNode - PageLayout component
*/

export default function Instance() {
  // variables
  const { instanceId } = useParams<{instanceId: string}>();
  const url = `vms/vm/${instanceId}`;
  const { handlePagePaths } = useGetRoutesPagePath();
  const handleFetchRequest = useHandleFetchRequest();
  const page = 'instance';

  useEffect(() => {
    document.title = 'Taiga Cloud | Instance Details';
  }, []);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    if (instanceId) {
      const getInstance = api.useFetchData<[], IVm>(url, 'get', signal);
      handleFetchRequest(page, 'get', getInstance);
    }
    return () => {
      controller.abort();
    };
  }, [url, instanceId, handleFetchRequest]);

  return (
    <PageLayout
      method='get'
      page={page}
      renderSuccess={
        <DetailsPageLayout
          dataTestIds={dataTestIdsVm}
          routeNav={handlePagePaths().instances}
          detailsPageName={page}
        />
      }
      renderEmpty={<NotFound />}
    />
  );
};
