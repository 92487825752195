import React, { useCallback, useEffect, useState } from 'react';

import { setInstance, setInstanceValidation } from 'actions/instance';
import { useInstance } from 'context/instance';
import { TImage } from 'types';

import Item from './Item';

const Items = ({images}: {images: TImage[]}) => {
  const [selectedImageId, setSelectedImageId] = useState<number>(0);
  const context = useInstance();
  const dispatch = context[1];
  const state = context[0];
  const { instance: { image: { uuid } } } = state;

  const isValid = useCallback(() => {
    dispatch(setInstanceValidation(false, 'image'));
    dispatch(setInstance('', 'error'));
  }, [dispatch]);

  useEffect(() => {
    let ignore = false;

    const imageObject = {
      uuid: images[0]?.versions[0]?.uuid,
      name: images[0]?.versions[0]?.name,
      image: images[0]?.versions[0]?.image,
    };

    if (!ignore) {
      if ((!uuid || uuid === '')) {
        dispatch(setInstance(imageObject, 'image'));
        isValid();
      }
    }

    return () => {
      ignore = true;
    };
  }, [dispatch, uuid, images, isValid]);

  const handleChooseImage = (id: number) => {
    setSelectedImageId(id);
  };

  return (
    <div className='row'>
      {images.map(image => (
        <Item
          key={image.id}
          image={image}
          selectedImageId={selectedImageId}
          handleImage={handleChooseImage}
        />
      ))}
    </div>
  );
};

export default Items;
