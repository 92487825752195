import React, { useState, useEffect } from 'react';

import NetworksList from 'feature/networks/Networks';
import { useDashboard } from 'context/dashboard';
import Subheader from 'components/Subheader';
import PortalNotification from 'portals/Notification';
import PageLayout from 'layouts/pages';
import { api } from 'helpers/api';
import { INetwork } from 'types';
import { useCleanState } from 'hooks/useCleanState';
import useHandleFetchRequest from 'hooks/useHandleFetchRequest';
import { setNewNetwork } from 'actions/dashboard';
import ModalWindowBulkOperations from 'feature/bulk-operations/delete/ModalWindowBulkOperations';
import BulkOperationsSection from 'feature/bulk-operations/delete/BulkOperationsSection';
import useSelectedOrDeselectedAllRemovingItems from 'hooks/useSelectedOrDeselectedAllRemovingItems';
import {
  TEST_ID_NETWORKS_PAGE_CREATE_NETWORK_BTN,
} from 'data-test-ids/data-testid-variables';
import { dataTestIdsNetworks } from 'data-test-ids';
import ModalWrapper from 'components/forms/create-network';
import { useTenants } from 'context/tenants';

import Empty from './Empty';

export default function Networks() {
  const [openForm, setOpenForm] = useState<boolean>(false);
  const [openPortalModalWindow, setOpenPortalModalWindow] = useState<boolean>(false);

  const context = useDashboard();
  const state = context[0];
  const dispatch = context[1];
  const contextTenants = useTenants();
  const stateTenants = contextTenants[0];
  const { selectedTenantId } = stateTenants;

  const { fetchFunc } = state;
  const { loading, error, data } = fetchFunc.networks.get;
  const url = `network/networks/${selectedTenantId}`;
  const handleFetchRequest = useHandleFetchRequest();
  const cleanState = useCleanState();
  const page = 'networks';

  useEffect(() => {
    document.title = 'Taiga Cloud | Networks';
  }, []);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    if (selectedTenantId) {
      const getNetworks = api.useFetchData<[], Array<INetwork>>(url, 'get', signal);
      handleFetchRequest(page, 'get', getNetworks);
    }
    return () => {
      controller.abort();
    };
  }, [url, page, selectedTenantId, handleFetchRequest]);

  useEffect(() => {
    return () => {
      cleanState('network', 'post');
      cleanState(page, 'post');
    };
  }, [cleanState]);

  const handleOpenModalWindow = (): void => {
    cleanState('network', 'post');
    setOpenForm(true);
    dispatch(
      setNewNetwork({
        tenant_uuid: '',
        cluster_uuid: '',
        network_name: '',
      }),
    );
  };

  const handleDeselectedAllItems = useSelectedOrDeselectedAllRemovingItems('networks', false);

  return (
    <>
      {!loading && !error &&
        <Subheader
          title='Networks'
          buttonTitle='Create Network'
          dataTestId={TEST_ID_NETWORKS_PAGE_CREATE_NETWORK_BTN}
          onClick = {handleOpenModalWindow}
          dataTestIds={dataTestIdsNetworks.subheader}
        />
      }
      {openForm &&
        <ModalWrapper
          onCancel={() => setOpenForm(false)}
          open={openForm}
          page='Networks'
          dataTestIds={dataTestIdsNetworks.modalForm}
        />
      }
      {data.length !== 0 &&
        <BulkOperationsSection
          setOpenPortalModalWindow={setOpenPortalModalWindow}
          page='networks'
          handleDeselectedAllItems={handleDeselectedAllItems}
        />
      }
      <PageLayout
        method='get'
        page='networks'
        renderEmpty={<Empty description='networks' />}
        renderSuccess={
          <NetworksList
            isShowCheckbox={data.length === 0 ? false : true}
          />
        }
      />
      <ModalWindowBulkOperations
        openPortalModalWindow={openPortalModalWindow}
        setOpenPortalModalWindow={setOpenPortalModalWindow}
        page='networks'
        itemsName='network'
      />
      {fetchFunc.network.post.message &&
        <PortalNotification
          text='success'
          isOneNitification={true}
        />
      }
    </>
  );
};
