import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useDashboard } from 'context/dashboard';
import PageLayout from 'layouts/pages';
import useHandleFetchRequest from 'hooks/useHandleFetchRequest';
import { api } from 'helpers/api';
import { INetwork } from 'types';
import DetailsPageLayout from 'layouts/details-page';
import { dataTestIdsNetwork } from 'data-test-ids';
import { useGetRoutesPagePath } from 'hooks/useGetRoutesPagePath';

import Empty from './Empty';

/**
 * @description The Network component this is a detailed page of a single network,
 * sets the title in the browser tab, makes a request to the API

 * @returns ReactNode - PageLayout component
*/

export default function Network() {
  // variables
  const contextDashboard = useDashboard();
  const { networkId } = useParams<{networkId: string}>();
  const state = contextDashboard[0];
  const url = `network/network/${networkId}`;
  const { fetchFunc } = state;
  const { data } = fetchFunc.network.get;
  const { handlePagePaths } = useGetRoutesPagePath();
  const handleFetchRequest = useHandleFetchRequest();

  useEffect(() => {
    document.title = 'Taiga Cloud | Network Details';
  }, [data]);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    if (networkId) {
      const getNetwork = api.useFetchData<[], INetwork>(url, 'get', signal);
      handleFetchRequest('network', 'get', getNetwork);
    }
    return () => {
      controller.abort();
    };
  }, [url, networkId, handleFetchRequest]);

  return (
    <PageLayout
      method='get'
      page='network'
      renderSuccess={
        <DetailsPageLayout
          detailsPageName='network'
          routeNav={handlePagePaths().networks}
          dataTestIds={dataTestIdsNetwork}
        />
      }
      renderEmpty={<Empty description='instances in your Network' />}
    />
  );
};
