import { useEffect, useState } from 'react';

import { setMaintenance } from 'actions/dashboard';
import { useDashboard } from 'context/dashboard';
import { TStatusMaintenance } from 'types';

export default function useGetMaintenance() {
  // state
  const [loading, setLoading] = useState<boolean>(false);

  // variables
  const contextDashboard = useDashboard();
  const dispatchDashboard = contextDashboard[1];
  const url = `${process.env.REACT_APP_STATUS_PAGE_API_ENV}/maintenance_and_incidents_and_scheduled`;

  useEffect(() => {
    (async () => {
      setLoading(true);
      const data: TStatusMaintenance = await fetch(url).then(res => res.json());
      dispatchDashboard(setMaintenance(data));
      setLoading(false);
    })();

  }, [dispatchDashboard, url]);

  return { loading };
};
