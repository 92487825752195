import { ReactElement } from 'react';

import Info from 'components/Info';
import Spinner from 'components/spinners/Spinner';
import InternalServerError from 'pages/error-statuses/InternalServerError';
import NotFound from 'pages/error-statuses/NotFound';
import { TFetchFuncPage, TFetchFuncRequestMethod } from 'types';

import { useDashboard } from 'context/dashboard';
import { Redirect } from 'react-router-dom';

type TProps = {
  method: TFetchFuncRequestMethod,
  page: TFetchFuncPage,
  renderSuccess: ReactElement,
  renderEmpty: ReactElement,
};

export default function PageLayout(props: TProps) {
  const { method, page, renderSuccess, renderEmpty } = props;
  const context = useDashboard();
  const state = context[0];
  const { fetchFunc } = state;
  const { loading, error, data: response, status } = fetchFunc[page][method];

  // const validCode5xx: RegExp = new RegExp(/5[0-9][0-9]/g);

  if (Array.isArray(response)) {
    if (status === 200 && response.length === 0) return <>{renderSuccess} {renderEmpty}</> ;
  };
  if (status === 403) return <Info status='error' msg='Forbidden' />;
  if (status === 401) return <Redirect  to='/login' />;
  if (status === 404) return (page === 'instances' || page === 'virtualmachine' || page === 'baremetal' || page === 'sshKeys') ?
    <>{renderSuccess} {renderEmpty}</> :
    <NotFound /> ;
  if (status === 500) return <InternalServerError code={status} />;
  if (error) return <Info status='error' msg={error} />;
  if (loading) return <Spinner />;
  if (status === 200) return renderSuccess;

  return null;
};
