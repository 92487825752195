import {
  TDataTestIdsSubheader,
  TDataTestIdsSubheaderBtnWrapper,
  TDataTestIdsSubheaderInfo,
} from 'data-test-ids/types/general-pages/subheader';

import Button from './buttons';
import Typography from './typography';

type TDataTestIdsInfo = {
  dataTestIds: TDataTestIdsSubheaderInfo,
};

type TInfo = {
  title: string,
};

type TDataTestIdsBtnWrapper = {
  dataTestIds: TDataTestIdsSubheaderBtnWrapper,
};

type TBtnWrapper = {
  onClick?: () => void,
  buttonTitle?: string,
  hideButton?: boolean,
  dataTestId?: string,
};

type TSubheader = TInfo & TBtnWrapper & {
  withoutIcon?: boolean,
  dataTestIds: TDataTestIdsSubheader,
  dataTestId?: string,
};

function Info (props: TInfo & TDataTestIdsInfo) {
  // props
  const { title, dataTestIds } = props;

  return(
    <div
      id='info'
      data-testid={dataTestIds.name}
      className='sm:flex-auto'
    >
      <Typography
        text={title}
        dataTestId={dataTestIds.name}
        fontWeight='medium'
      />
    </div>
  );
};

function BtnWrapper (props: TBtnWrapper & TDataTestIdsBtnWrapper) {
  // props
  const { onClick, buttonTitle, hideButton, dataTestIds } = props;

  return(
    <div
      id='btn-wrapper'
      data-testid={dataTestIds.name}
      className='row'
    >
      {!hideButton &&
        <Button
          type='button'
          uiStyle='primary'
          handleClick={onClick}
          title={buttonTitle}
          dataTestId={dataTestIds.button}
        />
      }
    </div>
  );
};

export default function Subheader (props: TSubheader) {
  // props
  const { title, onClick, buttonTitle, hideButton, dataTestIds, dataTestId } = props;

  // variables
  const { wrapper, btnWrapper } = dataTestIds;

  return(
    <div
      id='wrapper'
      data-testid={wrapper.name}
      className='flex items-start mb-[21px] mt-12 min-h-[3.5rem]'
    >
      <Info
        title={title}
        dataTestIds={wrapper.info}
      />
      <BtnWrapper
        dataTestIds={btnWrapper}
        onClick={onClick}
        buttonTitle={buttonTitle}
        hideButton={hideButton}
        dataTestId={dataTestId}
      />
    </div>
  );
};

