import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useDashboard } from 'context/dashboard';
import PageLayout from 'layouts/pages';
import { useGetRoutesPagePath } from 'hooks/useGetRoutesPagePath';
import useHandleFetchRequest from 'hooks/useHandleFetchRequest';
import { api } from 'helpers/api';
import DetailsPageLayout from 'layouts/details-page';
import { IIpv4 } from 'types/index';
import { dataTestIdsNetwork } from 'data-test-ids';

import Empty from './Empty';

export default function IPv4() {
  // variables
  const contextDashboard = useDashboard();
  const { ipv4Id } = useParams<{ipv4Id: string}>();
  const state = contextDashboard[0];
  const url = `network/ipv4/${ipv4Id}`;
  const { fetchFunc } = state;
  const { data } = fetchFunc.ip.get;
  const { handlePagePaths } = useGetRoutesPagePath();
  const handleFetchRequest = useHandleFetchRequest();

  useEffect(() => {
    document.title = 'Taiga Cloud | Ip Details';
  }, [data]);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    if (ipv4Id) {
      const getIp = api.useFetchData<[], IIpv4>(url, 'get', signal);
      handleFetchRequest('ip', 'get', getIp);
    }
    return () => {
      controller.abort();
    };
  }, [url, ipv4Id, handleFetchRequest]);

  return (
    <PageLayout
      method='get'
      page='ip'
      renderSuccess={
        <DetailsPageLayout
          detailsPageName='ip'
          routeNav={handlePagePaths().ipv4s}
          dataTestIds={dataTestIdsNetwork}
        />
      }
      renderEmpty={<Empty description='instances in your Ip' />}
    />
  );
};
