import { useTenants } from 'context/tenants';

/**
 * @description useGetRoutesPaths - hooks
 * @returns handlePagePaths
 */

export const useGetRoutesPagePath = () => {
  // variables
  const contextTenants = useTenants();
  const stateTenants = contextTenants[0];
  const { selectedTenantId } = stateTenants;

  /**
   *
   * @param id instanceId | networkId | ipv4Id => to get details page for one element
   * @param type instance type 'vm' | 'bm'  => to get details page for one element
   * @returns pagePaths object with routes
   */
  const handlePagePaths = (id?: string) => {
    const pagePaths = {
      instance: `/${selectedTenantId}/instances/${id}`,
      instances: `/${selectedTenantId}/instances`,
      newInstance: `/${selectedTenantId}/instances/new`,
      network: `/${selectedTenantId}/networks/${id}`,
      networks: `/${selectedTenantId}/networks`,
      sshKeys: `/${selectedTenantId}/sshkeys`,
      ipv4: `/${selectedTenantId}/ipv4s/${id}`,
      ipv4s: `/${selectedTenantId}/ipv4s`,
    };
    return pagePaths;
  };

  return { handlePagePaths };
};
