import { INetwork } from 'types';

export const INITIAL_STATE = {
  openEditModal: false,
  network: {
    address4: '',
    vm_uuid: '',
    network_name: '',
    prefix6: '',
    assigned_addresses6: [
      {
        vm_uuid: '',
        address6: '',
      },
    ],
    loading: false,
    statusResponse: 0,
    message: '',
    error: '',
    uuid: '',
    tenant_uuid: '',
    cluster_uuid: '',
    isSelected: false,
    timeoutShow: 0,
    timeoutClose: 0,
  },
};

export const OPEN_RENAME_MODAL = 'NETWORK.OPEN_RENAME_MODAL';
export const SET_NETWORK = 'NETWORK.SET_NETWORK';

export type TState = {
  openEditModal: boolean,
  network: INetwork
};

export type TOpenModalAction = {
  openEditModal: boolean,
  type: 'NETWORK.OPEN_RENAME_MODAL',
};

export type TSetNetworkAction = {
  network: INetwork,
  type: 'NETWORK.SET_NETWORK',
};

export type TReducer = {
  openEditModal: boolean,
  network: INetwork,
};

export function networkReducer(state: TState, action: TOpenModalAction | TSetNetworkAction)  {
  switch (action.type) {
  case OPEN_RENAME_MODAL: {
    return {
      ...state,
      openEditModal: action.openEditModal,
    };
  }
  case SET_NETWORK: {
    return {
      ...state,
      network: action.network,
    };
  }
  default: {
    throw Error('Unknown action: ' + action);
  }
  }
};
