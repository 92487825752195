import { useEffect, useState } from 'react';

import { useOktaAuth } from '@okta/okta-react';
import { useInstance } from 'context/instance';
import { setFlavors, setFlavorsFilters } from 'actions/instance';

import useSelectFlavorTab from './useSelectFlavorTab';

import { getFilterFlavors } from '../helpers/getFilterFlavor';

/**
 * @description
 * @returns error, loading
 */

export const useGetFlavors = () => {
  // state
  const [error, setError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  // variables
  const { authState } = useOktaAuth();
  const context = useInstance();
  const state = context[0];
  const dispatch = context[1];
  const token = authState?.accessToken?.accessToken;
  const { instance: { location } } = state;
  const { handleSelectedFlavorTab } = useSelectFlavorTab();

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    if(token) {
      setLoading(true);
      setError('');
      fetch(`${process.env.REACT_APP_API_URL}/info/flavors`, {
        method: 'GET',
        headers: {'Authorization': `Bearer ${token}`},
        signal,
      })
        .then(response => {
          if(response.ok) {
            setLoading(false);
            setError('');
            response.json().then((result) => {
              dispatch(setFlavors(result));
              const { A6000, H100, A100 } = getFilterFlavors(result);
              dispatch(setFlavorsFilters(A6000, 'A6000'));
              dispatch(setFlavorsFilters(H100, 'H100'));
              dispatch(setFlavorsFilters(A100, 'A100'));
              handleSelectedFlavorTab('NVIDIA RTX A6000', A6000[0]);
            });
          } else if (response.status === 404) {
            setLoading(false);
          } else {
            setLoading(false);
            response.json().then((result) => {
              setError(result.detail.detail);
            });
          };
        })
        .catch((error) => {
          setLoading(false);
          setError(`${error} Failed to load resource: The internet connection appears to be offline.`);
        });
    };
    return () => {
      controller.abort();
    };
  }, [token, dispatch, location, handleSelectedFlavorTab]);

  return { error, loading };
};
