export default function getClusterName(uuid: string) {
  if (uuid === 'bb7b0a68-265f-4734-a4ea-89911b2ddff7') {
    return ['Sweden, Europe', 'ndcloud-arcticcircle'];
  }
  if (uuid === '4ec2bb6d-cb56-4821-91eb-779eb5a45fd5') {
    return ['Norway, Europe', 'ndcloud-underground'];
  }
  if (uuid !== undefined || uuid !== null ) {
    return ['-', uuid || '-'];
  }

  return ['-', '-'];
};
