import React from 'react';

import Spinner from 'components/spinners/Spinner';
import { useDashboard } from 'context/dashboard';
import { ISshKey } from 'types';
import arrowGray from 'assets/icons/down-arrow-gray.svg';
import Typography from 'components/typography';

import Items from './Items';

import classes from '../instance.module.scss';

/**
 * @description
 * @returns Items
 */
export default function Content() {
  // variables
  const contextDashboard = useDashboard();
  const stateDashboard = contextDashboard[0];
  const { fetchFunc } = stateDashboard;
  const { data: allSshKeys, loading } = fetchFunc.sshKeys.get;
  const { sectionWrapperClass, fakeSelectDisableState } = classes;

  const sshKeys = allSshKeys.map((key: ISshKey) => ({...key, checked: false}));

  if (loading) return (
    <div className={`${sectionWrapperClass} justify-center`}>
      <Spinner />
    </div>
  );

  if (sshKeys.length === 0) return (
    <div className={`${fakeSelectDisableState} justify-between row`}>
      <Typography
        text="You don't have ssh keys"
        fontSize='sm'
        fontWeight='thin'
      />
      <img src={arrowGray} alt='arrow' className='w-5'/>
    </div>
  );

  return <Items />;
};
