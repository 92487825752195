import React, { useState } from 'react';

import IconCopy from 'assets/icons/IconCopy';
import Button from 'components/buttons';
import UIElementWithTooltip from 'feature/ui-element-with-tooltip';
import { TColor } from 'types';

type TProps = {
  /**
   * @default 'test_id_ui_copy_element'
   */
  dataTestId?: `test_id_${string}`,
  /**
   * @description pass the value to be copied
   * @default 'default'
   */
  value: string,
  /**
   * @description pass the value to be copied
   * @default 'fill-customColorDarkBlue'
   */
  iconColor?: `fill-${TColor}`,
};

/**
 * @description CopyComponent is used if you want to display the copy icon and
 * copy the corresponding value passed to it
 * @param props dataTestId, value, iconColor
 * @param value required
 *
 * @returns Button component
 */

export default function CopyComponent(props: TProps) {
  // state
  const [tooltipText, setTooltipText] = useState<string>('copy');

  // props
  const {
    value='default',
    dataTestId='test_id_ui_copy_element',
    iconColor='fill-customColorDarkBlue',
  } = props;

  // func
  const handleCopyClick = () => {
    navigator.clipboard.writeText(value);
    setTooltipText('Copied to clipboard!');
    setTimeout(() => setTooltipText('copy'), 1000);
  };

  return (
    <Button
      type='button'
      handleClick={handleCopyClick}
      uiStyle='without-style'
      dataTestId={dataTestId}
    >
      <UIElementWithTooltip
        text={tooltipText}
        element={
          <IconCopy
            customStyle={iconColor}
          />
        }
      />
    </Button>
  );
};
