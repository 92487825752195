import React, { useState, useEffect } from 'react';

import IPv4sList from 'feature/ipv4s/IPv4sList';
import { useDashboard } from 'context/dashboard';
import Subheader from 'components/Subheader';
import PageLayout from 'layouts/pages';
import { useCleanState } from 'hooks/useCleanState';
import ModalWindowBulkOperations from 'feature/bulk-operations/delete/ModalWindowBulkOperations';
import BulkOperationsSection from 'feature/bulk-operations/delete/BulkOperationsSection';
import useSelectedOrDeselectedAllRemovingIps from 'feature/ipv4s/useSelectedOrDeselectedAllRemovingIps';
import CreateNewIpv4ModalForm from 'components/forms/create-ipv4';
import { dataTestIdsIps } from 'data-test-ids';
import PortalNotification from 'portals/Notification';
import { useMakeRequestGetIpv4sImmediately } from 'hooks/useGetIps';

import Empty from './Empty';

export default function IPv4s() {
  // state
  const [openForm, setOpenForm] = useState<boolean>(false);
  const [openPortalModalWindow, setOpenPortalModalWindow] = useState<boolean>(false);

  // variables
  const context = useDashboard();
  const state = context[0];
  const { fetchFunc } = state;
  const { loading, error, data } = fetchFunc.ips.get;
  const cleanState = useCleanState();
  const page = 'ips';
  const fetchMethod = 'get';

  // hooks
  useMakeRequestGetIpv4sImmediately();

  useEffect(() => {
    document.title = 'Taiga Cloud | IPv4s';
  }, []);

  useEffect(() => {
    return () => {
      cleanState('ip', 'post');
      cleanState(page, 'post');
    };
  }, [cleanState]);

  const openCreateIpModalForm = () => {
    cleanState(page, 'post');
    setOpenForm(true);
  };

  const handleDeselectedAllItems = useSelectedOrDeselectedAllRemovingIps(false);

  return (
    <>
      {!loading && !error &&
        <Subheader
          title='IPv4s'
          buttonTitle='Create IPv4 Address'
          onClick = {openCreateIpModalForm}
          dataTestIds={dataTestIdsIps.subheader}
        />
      }
      {openForm &&
        <CreateNewIpv4ModalForm
          onCancel={() => setOpenForm(false)}
          open={openForm}
          dataTestIds={dataTestIdsIps.modalForm}
        />
      }
      {data.length !== 0 &&
        <BulkOperationsSection
          setOpenPortalModalWindow={setOpenPortalModalWindow}
          page='ips'
          handleDeselectedAllItems={handleDeselectedAllItems}
        />
      }
      <PageLayout
        method={fetchMethod}
        page={page}
        renderEmpty={<Empty description='IPv4s' />}
        renderSuccess={
          <IPv4sList
            isShowCheckbox={data.length === 0 ? false : true}
          />
        }
      />
      <ModalWindowBulkOperations
        openPortalModalWindow={openPortalModalWindow}
        setOpenPortalModalWindow={setOpenPortalModalWindow}
        page='ips'
        itemsName='ip'
      />
      {fetchFunc.ip.post.message &&
        <PortalNotification
          text='success'
          isOneNitification={true}
        />
      }
    </>
  );
};
