import React, { useEffect, lazy, Suspense } from 'react';

import { INetwork } from 'types';
import { useDashboard } from 'context/dashboard';
import useSelectedItems from 'feature/bulk-operations/delete/useSelectedItems';
import useSelectedOrDeselectedAllRemovingItems from 'hooks/useSelectedOrDeselectedAllRemovingItems';
import { setPreselectedRemovingItems } from 'actions/dashboard';
import { TEST_ID_NETWORKS_PAGE_SELECT_ALL_NETWORKS_BTN } from 'data-test-ids/data-testid-variables';
import { SpinnerLazyLoading } from 'components/spinners/SpinnerLazyLoading';

import NetworksTableComponent from './NetworksTableComponent';

import useOpenRenameModal from '../../hooks/useOpenRenameModal';

const RenameNetwork = lazy(() => import('components/forms/rename-network/RenameNetwork'));

type TProps = {
  isShowCheckbox: boolean,
};

export default function Networks(props: TProps) {
  const {
    isShowCheckbox,
  } = props;

  const context = useDashboard();
  const state = context[0];
  const dispatch = context[1];

  const { fetchFunc } = state;
  const { data } = fetchFunc.networks.get;

  const dataWithCustomValues: INetwork[] = data && data.map((item: INetwork) => (
    {
      ...item,
      isSelected: item.isSelected || false,
      loading: false,
      status: 0,
      message: '',
      error: '',
      isDeleted: false,
      timeoutShow: 100,
      timeoutClose: 1000,
      id: item.uuid,
    }
  ));

  const {
    handleOpenRenameModal,
    handleCloseRenameModal,
    state: rename,
  } = useOpenRenameModal();

  useEffect(() => {
    return () => {
      dispatch(setPreselectedRemovingItems('networks', 'uuid', []));
      dispatch(setPreselectedRemovingItems('networks', 'name', []));
    };
  }, [dispatch]);

  const handleSelectItems = useSelectedItems(
    dataWithCustomValues,
    'networks',
  );

  const isAllSelected = dataWithCustomValues.every((network: INetwork) => network.isSelected);
  const handleSelectedAllItems = useSelectedOrDeselectedAllRemovingItems('networks', true);
  const handleDeselectedAllItems = useSelectedOrDeselectedAllRemovingItems('networks', false);

  return (
    <>
      <NetworksTableComponent
        handleDeselectItems={handleDeselectedAllItems}
        handleSelectedAllItems={handleSelectedAllItems}
        isAllSelected={isAllSelected}
        data={dataWithCustomValues}
        handleOpenRenameModal={handleOpenRenameModal}
        handleSelectedItems={handleSelectItems}
        isShowCheckbox={isShowCheckbox}
        dataTestId={TEST_ID_NETWORKS_PAGE_SELECT_ALL_NETWORKS_BTN} //'select_all'
      />
      {rename.openEditModal &&
        <Suspense fallback={<SpinnerLazyLoading />}>
          <RenameNetwork
            data={rename.network}
            open={rename.openEditModal}
            onCancel={handleCloseRenameModal}
          />
        </Suspense>
      }
    </>
  );
};
