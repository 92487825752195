import { useDashboard } from 'context/dashboard';
import getClusterName from 'helpers/getClusterName';

export default function useGetItemsNetwork() {
  const contextDashboard = useDashboard();
  const state = contextDashboard[0];
  const { fetchFunc } = state;
  const { data } = fetchFunc.network.get;
  const cluster = getClusterName(data.cluster_uuid);

  const ITEMS: {title: string, value: string}[] = [
    {
      title: 'Subnet',
      value: data.prefix6,
    },
    {
      title: 'Cluster',
      value: cluster[1],
    },
    {
      title: 'UUID',
      value: data.uuid,
    },
    {
      title: 'Region',
      value: `${cluster[0]}`,
    },
  ];

  return ITEMS;
};
