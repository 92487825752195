import { useReducer } from 'react';

import { INetwork } from 'types';
import { useCleanState } from 'hooks/useCleanState';
import { defaultNetwork } from 'reducers/dashboard';

import {
  INITIAL_STATE,
  networkReducer,
  OPEN_RENAME_MODAL,
  SET_NETWORK,
  TOpenModalAction,
  TSetNetworkAction,
} from '../reducers/NetworkReducer';

export default function useOpenRenameModal() {

  const [state, dispatch] = useReducer(networkReducer, INITIAL_STATE);

  const cleanState = useCleanState();

  function setOpenEditModal(openEditModal: boolean): TOpenModalAction {
    return {
      type: OPEN_RENAME_MODAL,
      openEditModal,
    };
  };

  function setNetwork(network: INetwork ): TSetNetworkAction {
    return {
      type: SET_NETWORK,
      network,
    };
  };

  const handleOpenRenameModal = (network: INetwork) => {
    cleanState('network', 'patch');
    dispatch(setOpenEditModal(true));
    dispatch(setNetwork(network));
  };

  const handleCloseRenameModal = () => {
    dispatch(setOpenEditModal(false));
    dispatch(setNetwork(defaultNetwork));
  };

  return {handleOpenRenameModal, handleCloseRenameModal, state};
};
