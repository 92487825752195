async function request<TResponse>(
  url: string,
  config: RequestInit,
  version: string,
): Promise<TResponse> {

  const currentApiVersion = version === 'default' ? '/' : '/v1/';
  const apiUrl = `${process.env.REACT_APP_API_URL}${currentApiVersion}${url}`;
  const response = await fetch(`${apiUrl}`, config);
  const status = response.status;

  let msg = 'Whoops! Something wrong!';

  if (status === 422) {
    const responseJson = await response.json();
    msg = responseJson?.detail[0]?.msg;
  }

  if (status === 503) {
    const responseJson = await response.json();
    msg = responseJson?.detail?.msg;
  }

  if (status === 412) {
    const responseJson = await response.json();
    const getMsg = responseJson?.detail?.message ?
      responseJson?.detail?.message :
      responseJson?.detail?.detail;
    msg = getMsg;
  }

  if (status === 406 || status === 501) {
    const responseJson = await response.json();
    msg = responseJson?.detail?.message || responseJson?.detail?.detail;
  }

  if (status === 404) {
    const responseJson = await response.json();
    msg = responseJson?.detail?.detail || responseJson?.detail;
    // window.location.href = '/notfound';
  }

  if (status === 403) {
    window.location.href = '/login';
  }

  if (status === 200) {
    const responseJson = await response.json();
    return responseJson;
  }

  // Status: ${response.status}. Message: ${msg}

  throw new Error(`${msg}`, {cause: status});
}

export { request };
