import React from 'react';

import Spinner from 'components/spinners/Spinner';
import Info from 'components/Info';
import { useInstance } from 'context/instance';
import { useDashboard } from 'context/dashboard';
import { setInstance } from 'actions/instance';
import CustomSelect from 'components/input/Select';
import { TDataTestIdsNewVmMainIpsAddIpv4ModalForm } from 'data-test-ids/types/new-vm';
import { useFilterIps } from 'hooks/useFilterIps';

import NewIp from './NewIp';

type TProps = {
  isEmptySelectIp: string,
  dataTestIds: TDataTestIdsNewVmMainIpsAddIpv4ModalForm,
};

/**
 * @description AddIP4
 * @param param0
 * @returns
 */

export default function AddIP4(props: TProps) {
  // props
  const {
    isEmptySelectIp='',
    dataTestIds,
  } = props;

  const contextInstance = useInstance();
  const stateInstance = contextInstance[0];
  const dispatchInstance = contextInstance[1];
  const {
    instance: {
      selectedCluster: {
        uuid: selectedClusterUuid,
      },
      selectedIpv4,
      preselectedIpv4s,
    },
  } = stateInstance;

  const contextDashboard = useDashboard();
  const stateDashboard = contextDashboard[0];
  const { fetchFunc } = stateDashboard;
  const { error, loading } = fetchFunc.ips.get;
  const { byClusters: { norway, sweden } } = useFilterIps();

  const selectedIpv4DependsOnCluster = selectedClusterUuid === '4ec2bb6d-cb56-4821-91eb-779eb5a45fd5' ? norway.notAttached : sweden.notAttached;
  const ipv4sWithoutAddedIps = selectedIpv4DependsOnCluster.filter((el) => !preselectedIpv4s.includes(el.address4));

  const handleSelect = (name: string) => {
    dispatchInstance(setInstance(name, 'selectedIpv4'));
  };

  return (
    <div className={dataTestIds.wrapper.name}>
      {loading ?
        <Spinner spaceAroundSpinner /> :
        <>
          <CustomSelect
            handleChange={handleSelect}
            name='IPv4 Address'
            label='Choose Existing IPv4 Address'
            value={selectedIpv4}
            classNames='w-full'
            dataTestId={dataTestIds.wrapper.select} // 'select_ipv4_address'
            disabled={ipv4sWithoutAddedIps.length === 0 ? true : false}
            textDisabled={ipv4sWithoutAddedIps.length === 0 ? "You don't have addresses" : 'IPv4 address'}
            selectFeedback={isEmptySelectIp}
            options={ipv4sWithoutAddedIps}
            optionValue='address4'
          />
          <NewIp dataTestIds={dataTestIds.wrapper.newIp.name} />
        </>
      }
      {error &&
        <Info
          status='error'
          msg={error}
          dataTestId={dataTestIds.wrapper.error}
        />
      }
    </div>
  );
};
