import React from 'react';

import { useInstance } from 'context/instance';

import SectionWithTags from './SectionWithTags';
import Tag from './Tag';

import useRemoveIp from '../ips/useRemoveIp';

export default function Ipv4s() {
  const context = useInstance();
  const state = context[0];
  const { instance: { ips } } = state;
  const handleRemoveIp = useRemoveIp();

  const showIps = () => {
    return (
      ips?.map(item => (
        <Tag
          key={item.address4}
          name={item.address4}
          handleRemoveTag={handleRemoveIp}
          value={item.address4}
        />
      ))
    );
  };

  return (
    <SectionWithTags title={`IP address${ips?.length > 1 ? 'es' : ''}`} testDataId='ipv4_address'>
      <>{ips.length === 0 ? 'Choose IPv4 Address (optional)' : showIps()}</>
    </SectionWithTags>
  );
};
