import React from 'react';
import Spinner from 'components/spinners/Spinner';
import ModalWrapper from 'layouts/ModalWhiteBg';
import AlertMsg from 'components/alerts/AlertMsg';
import { useDashboard } from 'context/dashboard';

type TProps = {
  loading: boolean,
};

const LoadingAlert = ((props: TProps) => {
  // props
  const { loading } = props;
  // variables
  const contextDashboard = useDashboard();
  const stateDashboard = contextDashboard[0];
  const { instanceName } = stateDashboard;

  return (
    <ModalWrapper open={loading} onCancel={() => {}}>
      <div className='col py-[42px] all-items-center'>
        <Spinner spaceAroundSpinner />
        <p className='text-base font-medium mt-5 mb-[23px]'>
          Creating a new {instanceName}
        </p>
        <AlertMsg typeOfAlert='info'>
          <p>Please do not close your browser</p>
        </AlertMsg>
      </div>
    </ModalWrapper>
  );
});

export default LoadingAlert;
