import React from 'react';

import CustomSelect from 'components/input/Select';
import Typography from 'components/typography';
import { useTenants } from 'context/tenants';
import { setPreselectedTenant } from 'actions/tenants';
import { useHistory, useLocation } from 'react-router-dom';
import { useChangeTenant } from 'hooks/useChangeTenant';

/**
 * @description Tenants is a component contains a select,
 * which allows you to select a tenant or shows text with the name and
 * id of the tenant if there is only one tenant.
 *
 * @returns div
 */

export default function Tenants() {
  // variables
  const context = useTenants();
  const state = context[0];
  const dispatch = context[1];
  const { selectedTenant, tenants: { data }, selectedTenantId } = state;
  const selectValue = selectedTenantId === selectedTenant.uuid ? `${selectedTenant?.customer_name}` : '';
  const location = useLocation();
  const history = useHistory();
  const { changeTenant } = useChangeTenant();

  // func
  const handleChange = (name: string, uuid: string) => {
    if (location.pathname.includes('/instances/new')) {
      dispatch(setPreselectedTenant({...selectedTenant, uuid: uuid, customer_name: name}));
      history.push(`/${uuid}/instances/new`);
    };

    if (location.pathname !== '/' && !location.pathname.includes('/instances/new')) {
      const selectedPath = location.pathname.split('/');
      history.push(`/${uuid}/${selectedPath[2]}`);
      changeTenant(name, uuid);
    };

    if (location.pathname === '/') {
      history.push('/');
      changeTenant(name, uuid);
    };
  };

  return (
    <div
      className='col items-start'
      data-testid='test-id-main-header-tenants'
    >
      {data.length > 1 ?
        <CustomSelect
          name='tenant'
          label='tenant'
          id={selectedTenantId}
          value={selectValue}
          isShowLabel={false}
          classNames='min-w-[16.5rem]'
          disabled={selectValue === '' ? true : false}
          options={data}
          handleChange={handleChange}
          optionValue='customer_name'
          dataTestId='test-id-main-header-selected-tenant'
        />
        :
        <>
          <Typography
            text={selectedTenant?.customer_name}
            fontSize='s'
            dataTestId='test-id-main-header-selected-tenant-name'
          />
          <Typography
            text={selectedTenant?.uuid}
            fontSize='xxxs'
            color='customColorDarkBlue60'
            dataTestId='test-id-main-header-selected-tenant-uuid'
          />
        </>
      }
    </div>
  );
};
