import { IInstanceInitialStateReducer, ITabsItemFlavor } from 'types';
import { TFlavorTabs } from 'types';

import { defaultNetwork } from './dashboard';

export const SET_TAB = 'DASHBOARD.SET_TAB';
export const SET_INSTANCE = 'DASHBOARD.SET_INSTANCE';
export const SET_INSTANCE_VALIDATION = 'DASHBOARD.SET_INSTANCE_VALIDATION';
export const SET_FLAVORS = 'DASHBOARD.SET_FLAVORS';
export const SET_FLAVORS_FILTERS = 'DASHBOARD.SET_FLAVORS_FILTERS';


export type TFilterFlavors = {
  A6000: ITabsItemFlavor[],
  H100: ITabsItemFlavor[],
  A100: ITabsItemFlavor[],
};

export interface IAction {
  type: string,
  key: string,
  instance: {},
  tab: TFlavorTabs,
  validation: {},
  flavors: ITabsItemFlavor[],
  filterFlavors: TFilterFlavors,
};

const defaultFlavor = {
  uuid: '',
  name: '',
  slug: '',
  selected: false,
  cluster: {
    uuid: '',
    name: '',
  },
  customName: '',
  cpu_type: '',
  core_count: 0,
  ram: 0,
  storage_size: 0,
  gpu_type: '',
  gpu_count: 0,
  available: false,
  nvswitch_count: 0,
  nvswitch_type: '',
};

export const initialState: IInstanceInitialStateReducer = {
  instance: {
    name: '',
    sshKeys: [],
    network: defaultNetwork,
    image: {
      uuid: '',
      name: '',
      image: '',
    },
    sshKey: [],
    location: 'Norway',
    ips: [],
    notAttachedIpv4s: [],
    selectedIpv4: '',
    preselectedIpv4s: [],
    flavor: defaultFlavor,
    startup: '',
    error: '',
    selectedCluster: {
      name: '',
      uuid: '',
    },
    isLeavePage: false,
  },
  tab: 'NVIDIA A100',
  validation: {
    name: '',
    sshKey: '',
    network: '',
    image: '',
    flavor: '',
  },
  flavors: [defaultFlavor],
  filterFlavors: {
    A6000: [defaultFlavor],
    H100: [defaultFlavor],
    A100: [defaultFlavor],
  },
};

export function reducer(
  state: IInstanceInitialStateReducer,
  action: IAction,
): IInstanceInitialStateReducer {
  switch (action.type) {
  case SET_TAB:
    return {
      ...state,
      tab: action.tab,
    };
  case SET_INSTANCE:
    return {
      ...state,
      instance: {
        ...state.instance,
        [action.key]: action.instance,
      },
    };
  case SET_INSTANCE_VALIDATION:
    return {
      ...state,
      validation: {
        ...state.validation,
        [action.key]: action.validation,
      },
    };
  case SET_FLAVORS:
    return {
      ...state,
      flavors: action.flavors,
    };
  case SET_FLAVORS_FILTERS:
    return {
      ...state,
      filterFlavors: {
        ...state.filterFlavors,
        [action.key]: action.filterFlavors,
      },
    };
  default:
    throw new Error(`Unhandled action type: ${action.type}`);
  }
};
