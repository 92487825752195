import React from 'react';
import { useOktaAuth } from '@okta/okta-react';
import Typography from 'components/typography';

import UserIconComponent from './UserIconComponent';

import classes from '../../../index.module.scss';

/**
 * @description UserInfo this component is displayed
 * in the popup and displays information about the user.
 *
 * @returns div
 */

export default function UserInfo() {
  // variables
  const { authState } = useOktaAuth();
  const name = authState?.idToken?.claims.name || 'user';
  const email = authState?.idToken?.claims.email || 'user@email.com';

  // style
  const {
    userInfoBlockWrapperClass,
  } = classes;

  return (
    <div
      className={userInfoBlockWrapperClass}
      data-testid='test-id-main-header-user-info-pop-up'
    >
      <UserIconComponent
        dataTestId='test-id-main-header-user-info-pop-up-icon'
        isCursor={false}
      />
      <div className='ml-4 col justify-center'>
        <Typography
          text={name}
          fontSize='s'
          dataTestId='test-id-main-header-user-info-pop-up-name'
        />
        <Typography
          text={email}
          fontSize='s'
          color='customColorDarkBlue60'
          dataTestId='test-id-main-header-user-info-pop-up-email'
        />
      </div>
    </div>
  );
};
