import TagComponent from 'components/html-tags/Tag';

import { TProps } from './types';

/**
 * @description The Typography component can be used to create text tags like h1...h6 and p,
 * and can also be customized in appearance.
 * Use props {truncate} and {width} to truncate overflowing text with an ellipsis (…) if needed.
 * @param props text, fontWeight, color, textTag, dataTestId, fontSize, customStyle, truncate, width
 * @param text required
 *
 * @returns h1..h6 | p with placeholder text inside - TagComponent
*/

export default function Typography(props: TProps) {
  // props
  const {
    text='placeholder text',
    fontWeight='normal',
    color='customColorDarkBlue',
    textTag='p',
    dataTestId='dataTestId_text_element',
    fontSize='base',
    customStyle='',
    truncate='',
    width='',
  } = props;

  // variables
  const styleFont = `
    text-${fontSize}
    font-${fontWeight}
    text-${color}
    ${truncate}
    w-${width}
  `;

  const styles = `
    ${customStyle}
    ${styleFont}
  `;

  return (
    <TagComponent
      tag={textTag}
      dataTestId={dataTestId}
      className={styles}
      children={text}
    />
  );
};
