import dataImages from 'data/availableImages.json';
import dataFlavors from 'data/flavors.json';
import dataClusters from 'data/clusters.json';

function matchClusterUUID(uuid: string) {
  const matched = dataClusters.find((element) => {
    return element.uuid.toString().toLowerCase() === uuid.toString().toLowerCase();
  });
  if (matched) {
    return matched.name;
  }
  else
    return uuid;
}

function matchFlavorUUID(uuid: string) {
  const matched = dataFlavors.find((element) => {
    return element?.uuid?.toString().toLowerCase() === uuid?.toString().toLowerCase();
  });
  if (matched) {
    return matched.name;
  }
  else
    return uuid;
}

function matchImageUUID(uuid: string) {
  const matched = dataImages.find((element) => {
    return element?.uuid?.toString().toLowerCase() === uuid?.toString().toLowerCase();
  });
  if (matched) {
    return `${matched.name} ${matched.version} ${matched.name_version} `;
  }
  else
    return uuid;
}

export { matchClusterUUID, matchFlavorUUID, matchImageUUID };
