import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';

import Typography from 'components/typography';
import Button from 'components/buttons';
import { setPreselectedTenant, setSelectedTenant, setSelectedTenantId, setTenants } from 'actions/tenants';
import Spinner from 'components/spinners/Spinner';
import ColorFullIconLogo from 'assets/icons/logo/full/Color.svg';
import Info from 'components/Info';
import { useTenants } from 'context/tenants';
import { TTenant } from 'types/index';
import useLogout from 'hooks/useLogout';

/**
 * @description The Tenants component is a page of tenants,
 * sets the title in the browser tab, makes a request to the API

 * @returns div
*/

export default function Tenants() {
  // variables
  const contextTenants = useTenants();
  const state = contextTenants[0];
  const dispatch = contextTenants[1];
  const { selectedTenantId, tenants: { loading, error } } = state;
  const { authState } = useOktaAuth();
  const { handleLogout } = useLogout();

  useEffect(() => {
    document.title = 'Taiga Cloud | Tenants';
  }, []);

  useEffect(() => {
    if(!selectedTenantId) {
      dispatch(setTenants(true, 'loading'));
      fetch(`${process.env.REACT_APP_API_URL}/info/customers`, {
        method: 'GET',
        headers: {'Authorization': `Bearer ${authState?.accessToken?.accessToken}`},
      })
        .then(async (response) => {
          if(!response.ok) {
            dispatch(setTenants(response?.statusText || 'Whoops! Something wrong!', 'error'));
          } else {
            const data: TTenant[] = await response.json();
            dispatch(setTenants(data, 'data'));
            dispatch(setSelectedTenantId(data[0].uuid));
            dispatch(setPreselectedTenant(data[0]));
            dispatch(setSelectedTenant(data[0]));
            localStorage.setItem('tenantsStorage', JSON.stringify(data));
            localStorage.setItem('selectedTenantIdStorage', data[0].uuid);
            localStorage.setItem('selectedTenantStorage', JSON.stringify(data[0]));
          }
          dispatch(setTenants(false, 'loading'));
        });
    };
    return () => {};
  }, [dispatch, authState, selectedTenantId]);

  // func
  const redirectToLoginPage = () => {
    handleLogout();
    return <Redirect to='/login' />;
  };

  if (authState?.isAuthenticated && selectedTenantId) {
    return <Redirect to={{ pathname: '/' }}/>;
  };

  return (
    <div
      className='col items-center justify-center h-screen'
      data-testid='test_id_tenants_page'
    >
      <img src={ColorFullIconLogo} className='h-28' alt='logo-color' />
      {!error &&
        <>
          <Typography
            text='We are requesting your tenant'
            customStyle='m-4'
            color='customColorDarkBlue60'
          />
          <Typography
            text='Please, wait a moment...'
            fontSize='s'
          />
        </>
      }
      {(loading && !error) && <Spinner spaceAroundSpinner />}
      {error &&
        <>
          <Info
            status='error'
            msg={error}
          />
          <Typography
            text='In the meantime, here is what you can do:'
            fontSize='s'
            customStyle='mt-4'
          />
          <ul className='list-disc m-5 text-s'>
            <li>
              Refresh the page
            </li>
            <li>
              Try again in 30 minutes.
            </li>
            <li>
              Write us on the <a
                rel='noreferrer noopener'
                target='_blank'
                href='https://northerndata.atlassian.net/servicedesk/customer/portal/12'
                className='underline underline-offset-8'
              >
              support page
              </a> and tell us what happened
            </li>
          </ul>
          <Button
            uiStyle='primary'
            title='Login page'
            handleClick={redirectToLoginPage}
          />
        </>
      }
    </div>
  );
};
