import React, { useEffect } from 'react';
import { ExclamationCircleIcon } from '@heroicons/react/20/solid';

import { setInstance, setInstanceValidation } from 'actions/instance';
import { setFetchFunc } from 'actions/dashboard';
import { useDashboard } from 'context/dashboard';
import { useInstance } from 'context/instance';

import SectionWithTags from './SectionWithTags';
import Tag from './Tag';

export default function Keys() {
  const context = useInstance();
  const state = context[0];
  const dispatch = context[1];
  const { instance, validation } = state;

  const contextDashboard = useDashboard();
  const stateDashboard = contextDashboard[0];
  const dispatchDashboard = contextDashboard[1];
  const { fetchFunc } = stateDashboard;
  const { data: sshKeys } = fetchFunc.sshKeys.get;

  const handleRemoveKey = (uuid: string) => {
    const newArray = instance.sshKeys.filter(key => key.uuid !== uuid);
    const selectedKeys = sshKeys.map(
      item => item.uuid === uuid ? {...item, checked: false} : item);
    dispatchDashboard(setFetchFunc(selectedKeys, 'sshKeys', 'get', 'data'));
    dispatch(setInstance(newArray, 'sshKeys'));
  };

  const showKeys = () => {
    return (
      instance.sshKeys?.map(item => (
        <Tag
          key={item.uuid}
          name={item.name}
          handleRemoveTag={instance.sshKeys.length <= 6 ? handleRemoveKey : () => {}}
          value={item.uuid}
        />
      ))
    );
  };

  useEffect(() => {
    if(instance.sshKeys.length === 0) {
      dispatch(setInstanceValidation('', 'sshKey'));
    };
  }, [instance.sshKeys, dispatch]);

  return (
    <SectionWithTags title='Authentication' testDataId='authentication'>
      <>
        {validation.sshKey ?
          <>
            <ExclamationCircleIcon className='fill-customDarkRed w-[18px] mr-[10px]' />
            <p className='text-customDarkRed'> Add SSH Key</p>
          </>
          :
          instance.sshKeys.length === 0 ? 'Add SSH Key' : showKeys()
        }
      </>
    </SectionWithTags>
  );
};
