import React, { ChangeEvent } from 'react';
import { PencilIcon } from '@heroicons/react/24/solid';
import { Link } from 'react-router-dom';

import getClusterName from 'helpers/getClusterName';
import BodyRow from 'components/table-component/Cell';
import Table from 'components/table-component/Table';
import MenuComponent from 'components/menu';
import { INetwork } from 'types';
import Row from 'components/table-component/Row';
import CustomCheckbox from 'components/input/Checkbox';
import { useGetRoutesPagePath } from 'hooks/useGetRoutesPagePath';

type TProps = {
  data: INetwork[],
  handleOpenRenameModal: (network: INetwork) => void,
  handleSelectedItems: (event: ChangeEvent<HTMLInputElement>, uuid: string) => void,
  isAllSelected: boolean,
  handleDeselectItems: (event: ChangeEvent<HTMLInputElement>) => void,
  isShowCheckbox: boolean,
  handleSelectedAllItems: () => void,
  dataTestId: string
};

export default function NetworksTableComponent(
  {
    data,
    handleOpenRenameModal,
    handleSelectedItems,
    isAllSelected,
    handleDeselectItems,
    isShowCheckbox,
    handleSelectedAllItems,
    dataTestId,
  }: TProps,
) {
  const headData = ['Name', 'Subnet', 'Cluster', 'Region'];
  const { handlePagePaths } = useGetRoutesPagePath();

  return (
    <Table
      isAllSelected={isAllSelected}
      handleDeselectItems={handleDeselectItems}
      headData={headData}
      isShowCheckbox={isShowCheckbox}
      handleSelectedAllItems={handleSelectedAllItems}
      dataTestId={dataTestId}
    >
      <tbody className='divide-y divide-gray-200 bg-white'>
        {data?.map((network: INetwork) => {
          const element = (number: number) => network.uuid === data.at(number)?.uuid;
          const lastElement = element(-1) || element(-2) ? true : false;
          return (
            <Row key={network.uuid}>
              <BodyRow>
                <div className='mx-6 flex'>
                  <CustomCheckbox
                    type='checkbox'
                    name='isSelected'
                    handleChange={(event) => handleSelectedItems(event, network.uuid)}
                    value={network.network_name}
                    aria-label='isSelected'
                    isSelected={network.isSelected || false}
                    disabled={false}
                  />
                </div>
              </BodyRow>
              <BodyRow>
                <div className='truncate w-56'>
                  <Link
                    to={handlePagePaths(network.uuid).network}
                  >
                    {network.network_name}
                  </Link>
                </div>
              </BodyRow>
              <BodyRow >
                {network.prefix6}
              </BodyRow>
              <BodyRow>
                <div>
                  {getClusterName(network.cluster_uuid)[1]}
                </div>
              </BodyRow>
              <BodyRow>
                <div>{getClusterName(network.cluster_uuid)[0]}</div>
              </BodyRow>
              <BodyRow>
                <MenuComponent
                  uuid={network.uuid}
                  lastElement={lastElement}
                  route={handlePagePaths(network.uuid).network}
                  url='network/network'
                  actions={['DELETED', 'view']}
                  page='networks'
                  fetchMethod='delete'
                  itemName={network.network_name}
                >
                  <div
                    onClick={() => handleOpenRenameModal(network)}
                    className='text-[#54575e] font-medium h-10 row items-center justify-start cursor-pointer py-4 first:my-0 last:my-0 hover:bg-[#edf0f3]'
                  >
                    <PencilIcon
                      className='w-4 ml-6 mr-[13px] stroke-2 fill-[#757a85] cursor-pointer'
                    />
                    Rename
                  </div>
                </MenuComponent>
              </BodyRow>
            </Row>
          );
        })}
      </tbody>
    </Table>
  );
};
