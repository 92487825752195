import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Subheader from 'components/Subheader';
import { useDashboard } from 'context/dashboard';
import PageLayout from 'layouts/pages';
import { useCleanState } from 'hooks/useCleanState';
import VirtualMachinesActions from 'feature/instances/VirtualMachinesActions';
import ModalWindowBulkOperations from 'feature/bulk-operations/delete/ModalWindowBulkOperations';
import BulkOperationsSection from 'feature/bulk-operations/delete/BulkOperationsSection';
import useSelectedOrDeselectedAllRemovingItemsVms from 'hooks/useSelectedOrDeselectedAllRemovingItemsVms';
import { TEST_ID_VMS_PAGE_CREATE_VM_BTN } from 'data-test-ids/data-testid-variables';
import { dataTestIdsVms } from 'data-test-ids';
import { useGetRoutesPagePath } from 'hooks/useGetRoutesPagePath';
// import {
//   useMakeRequestGetInstancesByClick,
// } from 'hooks/useGetInstances';
import Button from 'components/buttons';
import { useMakeRequestGetBareMetalByClick } from 'hooks/useGetBareMetal';
import { useMakeRequestGetVmsByClick, useMakeRequestGetVmsImmediately } from 'hooks/useGetVms';
import { setSelectedInstanceType } from 'actions/dashboard';
import InstancesList from 'feature/instances';

import Empty from '../Empty';

/**
 * @description Instances is a component that immediately gets data about all instances
 * and shows the header, bulk actions, list of all vms in a table on the main Virtual Machines page
 * @returns React Fragment
 */

export default function InstancesComponent() {
  // state
  const [openPortalModalWindow, setOpenPortalModalWindow] = useState<boolean>(false);

  // variables
  const history = useHistory();
  const context = useDashboard();
  const state = context[0];
  const dispatch = context[1];
  const { fetchFunc, selectedInstanceType, instanceName } = state;
  const { error, status, data } = fetchFunc[selectedInstanceType].get;
  const cleanState = useCleanState();
  const { handlePagePaths } = useGetRoutesPagePath();
  const { handleGetVms } = useMakeRequestGetVmsByClick();
  const { handleGetBareMatel } = useMakeRequestGetBareMetalByClick();
  // const { handleGetInstances } = useMakeRequestGetInstancesByClick();
  const page = selectedInstanceType; //'instances';

  // hooks
  useMakeRequestGetVmsImmediately();

  useEffect(() => {
    document.title = 'Taiga Cloud | Instances';
    localStorage.setItem('selectedInstanceType', selectedInstanceType);
  }, [selectedInstanceType]);

  // func
  const handleOpenCreateVm = (): void => {
    cleanState('instance', 'post');
    history.push(handlePagePaths().newInstance);
  };

  const handleDeselectedAllItems = useSelectedOrDeselectedAllRemovingItemsVms(false, page);

  // const handleSelectAllInstance = () => {
  //   dispatch(setSelectedInstanceType('All'));
  //   handleGetInstances();
  // };

  const handleSelectVms = () => {
    handleGetVms();
    dispatch(setSelectedInstanceType('virtualmachine'));
  };

  const handleSelectBm = () => {
    handleGetBareMatel();
    dispatch(setSelectedInstanceType('baremetal'));
  };

  return (
    <>
      {(!error || (status === 404)) &&
      <>
        <Subheader
          title='Instances'
          buttonTitle={`Create New ${instanceName}`}
          onClick = {handleOpenCreateVm}
          dataTestId={TEST_ID_VMS_PAGE_CREATE_VM_BTN}
          dataTestIds={dataTestIdsVms.subheader}
        />
        <>
          <div className='row my-4'>
            {/* <Button
              title='All'
              uiStyle='tab'
              handleClick={handleSelectAllInstance}
              customStyle={selectedInstanceType === 'All' ? 'border border-customColorLightGreen' : '' }
            /> */}
            <Button
              title='Virtual machines'
              uiStyle='tab'
              handleClick={handleSelectVms}
              customStyle={selectedInstanceType === 'virtualmachine' ? 'border border-customColorLightGreen mx-1' : 'mx-1' }
            />
            <Button
              title='Bare metal'
              uiStyle='tab'
              handleClick={handleSelectBm}
              customStyle={selectedInstanceType === 'baremetal' ? 'border border-customColorLightGreen' : '' }
            />
          </div>
          <hr />
        </>
      </>
      }
      <div className='row mt-4'>
        {(selectedInstanceType === 'baremetal') ?
          null :
          <>
            {data.length !== 0 &&
              <VirtualMachinesActions
                page={page}
              />
            }
            {data.length !== 0 &&
              <BulkOperationsSection
                setOpenPortalModalWindow={setOpenPortalModalWindow}
                page={page}
                handleDeselectedAllItems={handleDeselectedAllItems}
              />
            }
          </>
        }
      </div>
      <PageLayout
        method='get'
        page={page}
        renderEmpty={
          <Empty
            description='instances'
          />
        }
        renderSuccess={
          <InstancesList
            isShowCheckbox={data.length === 0 ? false : true}
          />
        }
      />
      <ModalWindowBulkOperations
        openPortalModalWindow={openPortalModalWindow}
        setOpenPortalModalWindow={setOpenPortalModalWindow}
        page={page}
        itemsName='virtual machine'
      />
    </>
  );
};
