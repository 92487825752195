import { useMemo } from 'react';

import { ITabsItemImage } from 'types';

import ubuntuImage from 'assets/images/ubuntu.png';
import debianImage from 'assets/images/debian.png';
// import rockyImage from 'assets/images/rocky.png';

export const useFilterImages = (data: ITabsItemImage[]) => {

  const debian: ITabsItemImage[] = data
    .filter((item: ITabsItemImage) => item.uuid === '789ee351-f157-495b-98fa-5acbffffdfe7')
    .map((item: ITabsItemImage) =>  ({
      ...item,
      name: item?.name.replace(' without drivers', ''),
      image: 'debian',
    }));

  const allUbuntu: ITabsItemImage[] = data
    .filter((item: ITabsItemImage) => item.name.includes('Ubuntu'))
    .map((item: ITabsItemImage) =>  ({
      ...item,
      name: item?.name.replace(' without drivers', ''),
      image: 'ubuntu',
    }));

  const ubuntu: ITabsItemImage[] = allUbuntu
    .filter((item: ITabsItemImage) => (
      item.uuid !== '3d790d54-49cd-404e-87e6-3fcdbb6f131a' &&
      item.uuid !== '11ad4e88-5829-4cb5-8593-25fe602a0df8'
    ));

  const images = useMemo(() => [
    {
      id: 0,
      name: 'ubuntu',
      logo: ubuntuImage,
      versions: ubuntu,
    },
    {
      id: 1,
      name: 'debian',
      logo: debianImage,
      versions: debian,

    },
    // {
    //   id: 2,
    //   name: 'rockylinux',
    //   logo: rockyImage,
    //   versions: [],
    // },
  ], [debian, ubuntu]);

  return images;
};

