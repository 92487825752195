import React, { useState } from 'react';

import { useDashboard } from 'context/dashboard';
import { IModalForm, TNewSshKey } from 'types';
import { api } from 'helpers/api';
import { ISshKey } from 'types';
import useHandleFetchRequest from 'hooks/useHandleFetchRequest';
import { setFetchFunc, setNewSshKey } from 'actions/dashboard';
import PortalModal from 'portals/Modal';
import FormModalTemplate from 'layouts/FormModalTemplate';
import { TDataTestIdsPortalModal } from 'data-test-ids/types/general-pages/portalModal';
import { useInstance } from 'context/instance';
import { setInstance, setInstanceValidation } from 'actions/instance';
import { useTenants } from 'context/tenants';

import Content from './Content';

type TProps = IModalForm & {
  dataTestIds: TDataTestIdsPortalModal,
};

export default function ModalWrapper(props: TProps) {
  // state
  const [formData, updateFormData] = useState<TNewSshKey>({
    tenant_uuid: '',
    name: '',
    key: '',
  });
  const [inputNameError, setInputNameError] = useState<string>('');
  const [sshKeyError, setSshKeyError] = useState<string>('');

  // props
  const { onCancel, open, dataTestIds } = props;

  // variables
  const context = useDashboard();
  const state = context[0];
  const dispatch = context[1];
  const contextInstance = useInstance();
  const stateInstance = contextInstance[0];
  const dispatchInstance = contextInstance[1];
  const contextTenants = useTenants();
  const stateTenants = contextTenants[0];
  const { selectedTenantId } = stateTenants;
  const { fetchFunc } = state;
  const { loading, error, status } = fetchFunc.sshKeys.post;
  const { data: allSshKeys } = fetchFunc.sshKeys.get;
  const url = `sshkeys/sshkey/${selectedTenantId}`;
  const page = 'sshKey';
  const fetchMethod = 'post';
  const handleFetchRequest = useHandleFetchRequest();
  const hideBtns = status > 0 || loading || error ? true : false;
  const { instance } = stateInstance;
  // func
  const handleSubmit = async () => {
    if (!formData.name) {
      return setInputNameError('*Please enter Name');
    };
    if (!formData.key) {
      return setSshKeyError('*Please enter correct SSH Key');
    };
    setInputNameError('');
    setSshKeyError('');
    if (selectedTenantId) {
      const request = api.useFetchData<TNewSshKey, ISshKey>(url, fetchMethod, null, formData );
      handleFetchRequest(page, fetchMethod, request);
      const newSshKey = await request;
      dispatch(setFetchFunc([...allSshKeys, newSshKey], 'sshKeys', 'get', 'data'));
      const isKeysLess6 = instance.sshKeys.length === 6 && !newSshKey.checked;
      if (isKeysLess6) {
        onCancel();
        return () => {};
      } else {
        const preselectNewSshKey = {...newSshKey, checked: true};
        dispatchInstance(setInstance([...instance.sshKeys, preselectNewSshKey], 'sshKeys'));
        dispatchInstance(setInstanceValidation(false, 'sshKey'));
        dispatchInstance(setInstance('', 'error'));
      }
      onCancel();
    };
  };

  const handleCloseModalForm = () => {
    dispatch(
      setNewSshKey({
        tenant_uuid: '',
        name: '',
        key: '',
      }),
    );
    onCancel();
  };

  return (
    <PortalModal
      open={open}
      onCancel={handleCloseModalForm}
      dataTestId={dataTestIds.portalModal.name}
    >
      <FormModalTemplate
        handleSubmitButtonName='Add'
        onCancel={handleCloseModalForm}
        handleSubmit={handleSubmit}
        header='Add SSH Key'
        hideBtns={hideBtns}
        dataTestIds={dataTestIds.portalModal.form}
      >
        <Content
          handleInputNameError={setInputNameError}
          handleSshKeyError={setSshKeyError}
          inputNameError={inputNameError}
          sshKeyError={sshKeyError}
          dataTestIds={dataTestIds.portalModal.form.content}
          handleUpdateForm={updateFormData}
          formData={formData}
        />
      </FormModalTemplate>
    </PortalModal>
  );
};
