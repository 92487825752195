import NotificationWrapper from 'components/notifications/NotificationWrapper';
import { createPortal } from 'react-dom';
import { INotification } from 'types';

type TProps = {
  isOneNitification: boolean,
  text: 'success' | 'error' | 'warning' | 'info',
  description?: string,
  notifications?: INotification[],
};

export default function PortalNotification(props: TProps) {

  const {
    text,
    description,
    notifications,
    isOneNitification,
  } = props;

  return (
    <>
      {createPortal(
        <NotificationWrapper
          text={text}
          description={description}
          notifications={notifications}
          isOneNitification={isOneNitification}
        />,
        document.body,
      )}
    </>
  );
}
